import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Ionic
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { IonicModule } from '@ionic/angular';

// Bootstrap UI
import { NgbModule, NgbDatepickerI18n, NgbDateParserFormatter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { I18n, CustomDatepickerI18n } from '@core/services/input-datepicker/language-datepicker.service';
import { NgbDateCustomParserFormatter, CustomDatePickerConfig } from '@core/services/input-datepicker/format-datepicker.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    NgbModule
  ],
  exports: [
    IonicModule,
    NgbModule
  ],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDatepickerConfig, useClass: CustomDatePickerConfig }
  ]
})
export class IonicArqModule {
  static forRoot(): ModuleWithProviders<IonicArqModule> {
    return {
      ngModule: IonicArqModule,
      providers: [
        {
          provide: RouteReuseStrategy,
          useClass: IonicRouteStrategy
        }
      ]
    };
  }
}
