import { NgModule } from '@angular/core';
import { GenericModalComponent } from '@shared/modules/generic-modal/generic-modal.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbDatepickerConfig, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n, I18n } from '@core/services/input-datepicker/language-datepicker.service';
import {
  CustomDatePickerConfig,
  NgbDateCustomParserFormatter
} from '@core/services/input-datepicker/format-datepicker.service';
import { DynamicCustomDatepickerService } from '@app/core/services/input-datepicker/dynamic-custom-datepicker.service';

@NgModule({
  declarations: [
    GenericModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDatepickerConfig, useClass: CustomDatePickerConfig },
    { provide: NgbDatepickerConfig, useClass: DynamicCustomDatepickerService }
  ],
  exports: [
    GenericModalComponent
  ],
  entryComponents: [
    GenericModalComponent
  ]
})
export class GenericModalModule {
}
