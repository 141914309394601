import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GenericModalComponent } from '@shared/modules/generic-modal/generic-modal.component';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private modalController: ModalController
  ) {
  }

  private err$ = new BehaviorSubject<CustomError>(null);

  setErrors(message: string, url: string) {
    const error = new CustomError();
    error.url = url;
    error.message = message;
    this.err$.next(error);
  }

  getError() {
    return this.err$;
  }

  getErrorAsObservable() {
    return this.err$.asObservable();
  }

  resetError() {
    return this.err$.next(null);
  }

  async showError(): Promise<void> {
    const modal = await this.modalController.create({
      component: GenericModalComponent,
      cssClass: 'error-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        iconToShow: 'icon-alert',
        text: this.err$.getValue().message,
        firstButton: { text: 'BUTTON.ACCEPT' }
      }
    });

    await modal.present();
  }
}

export class CustomError {
  status: string | number;
  message: string;
  buttonName?: string;
  url?: string;

  constructor() {
    this.message = '';
    this.status = '';
  }
}
