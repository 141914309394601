/* tslint:disable */
import { Injectable } from '@angular/core';
import { SwaggerSpacesDefinitionsBaseService } from '../swagger-spaces-definitions-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { SwaggerSpacesDefinitionsRequestBuilder } from '../swagger-spaces-definitions-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Bookings } from '../models/bookings';


/**
 * Operations about bookings rooms
 */
@Injectable({
  providedIn: 'root',
})
export class BookingsService extends SwaggerSpacesDefinitionsBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getListBookingsForRoomsAndDay
   */
  static readonly GetListBookingsForRoomsAndDayPath = '/bookings';

  /**
   * Get list the Bookings for rooms and day.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getListBookingsForRoomsAndDay()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListBookingsForRoomsAndDay$Response(params: {

    /**
     * RoomId from query.
     */
    roomId: number;

    /**
     * Date from query.
     */
    date: string;

  }): Observable<Array<Bookings>> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(BookingsService.GetListBookingsForRoomsAndDayPath, 'get');
    if (params) {
      req.query('roomId', params.roomId, {});
      req.query('date', params.date, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Array<Bookings>;
      })
    );
  }

  /**
   * Get list the Bookings for rooms and day.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getListBookingsForRoomsAndDay$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getListBookingsForRoomsAndDay(params: {

    /**
     * RoomId from query.
     */
    roomId: number;

    /**
     * Date from query.
     */
    date: string;

  }): Observable<Array<Bookings>> {

    return this.getListBookingsForRoomsAndDay$Response(params).pipe(
      map(r => r as Array<Bookings>)
    );
  }

  /**
   * Path part for operation addBookings
   */
  static readonly AddBookingsPath = '/bookings';

  /**
   * Add bookings.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `addBookings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBookings$Response(params: {

    /**
     * Bookings lock.
     */
    body: Bookings
  }): Observable<Bookings> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(BookingsService.AddBookingsPath, 'post');
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Bookings;
      })
    );
  }

  /**
   * Add bookings.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `addBookings$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBookings(params: {

    /**
     * Bookings lock.
     */
    body: Bookings
  }): Observable<Bookings> {

    return this.addBookings$Response(params).pipe(
      map(r => r as Bookings)
    );
  }

  /**
   * Path part for operation deleteBookings
   */
  static readonly DeleteBookingsPath = '/rooms/{id}';

  /**
   * Delete a bookins.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `deleteBookings()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBookings$Response(params: {
    id: number;

  }): Observable<void> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(BookingsService.DeleteBookingsPath, 'delete');
    if (params) {
      req.path('id', params.id, {});
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Delete a bookins.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `deleteBookings$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBookings(params: {
    id: number;

  }): Observable<void> {

    return this.deleteBookings$Response(params).pipe(
      map(r => r as void)
    );
  }

}
