import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-space-assign-card',
  templateUrl: './space-assign-card.component.html',
  styleUrls: ['./space-assign-card.component.scss'],
})
export class SpaceAssignCardComponent implements OnInit {

  @Input() card;
  @Input() selectTab;
  showData: boolean = false;
  showAssign: boolean = false;
  disabled: boolean;
  @Output() showAssignScreen: EventEmitter<any> = new EventEmitter();
  @Output() cardToAssgin: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
    this.disabled = this.card.parkingplace && this.card.parkingplace.disabled || this.card.disabled;
   }

  selectCard() {
    this.showData = !this.showData;
  }

  assignCard(card) {
    this.showAssign = !this.showAssign;
    this.showAssignScreen.emit(this.showAssign);
    this.cardToAssgin.emit(card);
  }
}
