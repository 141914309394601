import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services/auth/auth.service';
import { CustomLoggerService } from '@core/services/logger/logger.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { mergeMap } from 'rxjs/operators';
import { MiddlewareService } from '@app/shell/middleware/middleware.service';

export interface CustomHeaders {
  [name: string]: string;
}

export interface HttpCustomOptions {
  baseUrl?: string;
  forceHttps?: boolean;
  customHeaders?: CustomHeaders;
}

/**
 * Interceptor for customizing http requests.
 * It will take into account the environment's endpoint-specific headers and http protocol config
 */
@Injectable()
export class HttpCustomInterceptor implements HttpInterceptor {

  private accessToken: string;
  private authHeader = {};
  private customHeaders: CustomHeaders;
  private interceptorConfig: HttpCustomOptions;

  constructor(
    private readonly auth: AuthService,
    private readonly middleware: MiddlewareService,
    private readonly logger: CustomLoggerService,
    private readonly utils: UtilsService
  ) {
  }

  /**
   * Helper function to handle setting request url considering the baseUrl provided by config
   * and the protocol (https) in case the option 'forceHttps' is enabled for that specific endpoint.
   *
   * If the request url already contains protocol (http or https) it will ignore the baseUrl
   *
   * @param interceptorConfig: HttpCustomOptions
   * @param requestUrl: string
   * @param requestEndpoint: any
   */
  setUrl(interceptorConfig: HttpCustomOptions, requestUrl: string, requestEndpoint: any): string {
    const baseUrl = this.chooseBaseUrlBy(requestEndpoint.baseUrl);
    requestUrl = requestUrl.toLowerCase().startsWith('http') ? requestUrl : baseUrl.toString() + requestUrl;
    return interceptorConfig.forceHttps ? requestUrl.replace('http://', 'https://') : requestUrl;
  }

  /**
   * Helper to choose type of url, ending with "app" or "gwy2" if actual flow has activated "urlFlowActive" flag.
   *
   * @param requestUrl: string
   */
  chooseBaseUrlBy(requestUrl: string): string {
    return this.middleware.getBaseUrlGatewayIfNecessary(requestUrl);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Secure by default
    return this.auth.getAccessToken$()
      .pipe(mergeMap((accessToken) => {

        this.accessToken = accessToken;
        const requestEndpoint = this.utils.matchRequestEndpoint(request);
        this.interceptorConfig = this.utils.getInterceptorConfig(requestEndpoint, 'HttpCustom') || {};

        // Build authorization header
        this.authHeader = {
          Authorization: `Bearer ${this.accessToken}`
        };

        this.customHeaders = {...this.interceptorConfig.customHeaders, ...this.authHeader};

        this.logger.log(`HttpCustomConfig for url: ${request.url} \n is: ${JSON.stringify(this.interceptorConfig)}`);

        request = request.clone({
          setHeaders: this.customHeaders,
          url: this.setUrl(this.interceptorConfig, request.url, requestEndpoint)
        });

        return next.handle(request);
      }));
  }
}
