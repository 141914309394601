import { Injectable } from '@angular/core';
import { ConfigService } from '@core/services/config/config.service';
import { Config } from '@core/models/envConfig/config.interface';

export interface LogTypes {
  log?: string;
  warn?: string;
  error?: string;
}
const logTypes: LogTypes = {
  log: 'log',
  warn: 'warn',
  error: 'error'
};

@Injectable({
  providedIn: 'root'
})

/**
 * Custom wrapper for logging purposes. It can be enabled/disabled by environment configs and
 * used combined with the custom error handler
 *
 * Use preferably this wrapper instead of console.log/warn/error so as to be able to turn the feature
 * on or off when required
 */
export class CustomLoggerService {

  private enable;
  private envConfig: Config;

  constructor(
    private readonly config: ConfigService
  ) {
    this.envConfig = this.config.config;
  }

  /**
   * Sends log to both our custom handling and Kibana. If customLog is disabled, it only sends
   * error logs to Kibana for tracking
   * @param message: The message to be logged
   * @param logType (optional; log by default): Indicates the log type (log, warn or error) so as to act accordingly
   */
  log(message: any, logType?: string) {
    this.enable = this.envConfig.app.features.customLog;
    if (this.enable) {
      logType && logTypes[logType.toLowerCase()] ? console[logTypes[logType.toLowerCase()]](message) : console.log(message);
    }
  }
}
