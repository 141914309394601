import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

function padNumber(value: number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return '';
    }
}

function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct {
      if (value) {
        const dateParts = value.trim().split('-');
        return {year: toInteger(dateParts[0]), month: toInteger(dateParts[1]), day: toInteger(dateParts[2])};
      }
      return null;
    }

    format(date: NgbDateStruct): string {
      let stringDate = '';
      if (date) {
          stringDate += isNumber(date.day) ? padNumber(date.day) + '/' : '';
          stringDate += isNumber(date.month) ? padNumber(date.month) + '/' : '';
          stringDate += date.year;
      }
      return stringDate;
    }
}

@Injectable()
export class CustomDatePickerConfig extends NgbDatepickerConfig {
    constructor() {
        super();
        this.navigation = 'arrows';
    }
}
