/* tslint:disable */
import { Injectable } from '@angular/core';
import { SwaggerSpacesDefinitionsBaseService } from '../swagger-spaces-definitions-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { SwaggerSpacesDefinitionsRequestBuilder } from '../swagger-spaces-definitions-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Rooms } from '../models/rooms';


/**
 * Operations about rooms
 */
@Injectable({
  providedIn: 'root',
})
export class RoomsService extends SwaggerSpacesDefinitionsBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getListRoomsAvailabilityForDay
   */
  static readonly GetListRoomsAvailabilityForDayPath = '/rooms';

  /**
   * Get list the rooms with availability for the selected day.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getListRoomsAvailabilityForDay()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListRoomsAvailabilityForDay$Response(params: {

    /**
     * Date from query.
     */
    date: string;

  }): Observable<Array<Rooms>> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(RoomsService.GetListRoomsAvailabilityForDayPath, 'get');
    if (params) {
      req.query('date', params.date, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Array<Rooms>;
      })
    );
  }

  /**
   * Get list the rooms with availability for the selected day.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getListRoomsAvailabilityForDay$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getListRoomsAvailabilityForDay(params: {

    /**
     * Date from query.
     */
    date: string;

  }): Observable<Array<Rooms>> {

    return this.getListRoomsAvailabilityForDay$Response(params).pipe(
      map(r => r as Array<Rooms>)
    );
  }

}
