<header class="p-3 d-flex justify-content-between align-items-center" *ngIf="headerService.isHeaderVisible">
  <img class="header-logo" src="assets/img/logo/aegon-logo-principal.png" alt=""/>
  <div class="header-title-text">
    <span [translate]="'HEADER.TITLE1'"></span> <span [translate]="'HEADER.TITLE2'"></span>
  </div>
  <div class="header-user d-flex align-items-center dropdown">
    <div class="header-user-name">
      {{ userCompleteName }}
    </div>
    <div class="header-user-pic rounded-circle ml-2">
      <img class="img-fluid" src="assets/img/icon-user.png" alt=""/>
    </div>
    <div class="dropdown-content">
      <a (click)="logoutConfirmation()" [translate]="'HEADER.LOGOUT'" class="pointer"></a>
    </div>
  </div>
</header>
