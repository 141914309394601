/* tslint:disable */
import { Injectable } from '@angular/core';
import { SwaggerSpacesDefinitionsBaseService } from '../swagger-spaces-definitions-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { SwaggerSpacesDefinitionsRequestBuilder } from '../swagger-spaces-definitions-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LockedRooms } from '../models/locked-rooms';


/**
 * Operations about lock rooms
 */
@Injectable({
  providedIn: 'root',
})
export class LockRoomsService extends SwaggerSpacesDefinitionsBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addLockRooms
   */
  static readonly AddLockRoomsPath = '/lockrooms';

  /**
   * Add lock rooms.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `addLockRooms()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLockRooms$Response(params: {

    /**
     * Rooms lock.
     */
    body: LockedRooms
  }): Observable<LockedRooms> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(LockRoomsService.AddLockRoomsPath, 'post');
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as LockedRooms;
      })
    );
  }

  /**
   * Add lock rooms.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `addLockRooms$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLockRooms(params: {

    /**
     * Rooms lock.
     */
    body: LockedRooms
  }): Observable<LockedRooms> {

    return this.addLockRooms$Response(params).pipe(
      map(r => r as LockedRooms)
    );
  }

  /**
   * Path part for operation deleteLockRooms
   */
  static readonly DeleteLockRoomsPath = '/lockrooms/{id}';

  /**
   * Delete a lock rooms.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `deleteLockRooms()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLockRooms$Response(params: {
    id: number;

  }): Observable<void> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(LockRoomsService.DeleteLockRoomsPath, 'delete');
    if (params) {
      req.path('id', params.id, {});
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Delete a lock rooms.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `deleteLockRooms$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLockRooms(params: {
    id: number;

  }): Observable<void> {

    return this.deleteLockRooms$Response(params).pipe(
      map(r => r as void)
    );
  }

}
