/* tslint:disable */
import { Injectable } from '@angular/core';
import { SwaggerSpacesDefinitionsBaseService } from '../swagger-spaces-definitions-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { SwaggerSpacesDefinitionsRequestBuilder } from '../swagger-spaces-definitions-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmployeePbi } from '../models/employee-pbi';
import { PbiAuthDtoIn } from '../models/pbi-auth-dto-in';
import { PbiAuthDtoOut } from '../models/pbi-auth-dto-out';


/**
 * Operations about report of PowerBI
 */
@Injectable({
  providedIn: 'root',
})
export class PowerBiReportService extends SwaggerSpacesDefinitionsBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation auth
   */
  static readonly AuthPath = '/pbi/auth';

  /**
   * Login PBI.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `auth()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  auth$Response(params: {
  
    /**
     * Login data
     */
    body: PbiAuthDtoIn
  }): Observable<PbiAuthDtoOut> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(PowerBiReportService.AuthPath, 'post');
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PbiAuthDtoOut;
      })
    );
  }

  /**
   * Login PBI.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `auth$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  auth(params: {
  
    /**
     * Login data
     */
    body: PbiAuthDtoIn
  }): Observable<PbiAuthDtoOut> {

    return this.auth$Response(params).pipe(
      map(r => r as PbiAuthDtoOut)
    );
  }

  /**
   * Path part for operation getEmployeesByIdForPbi
   */
  static readonly GetEmployeesByIdForPbiPath = '/pbi/employees/{id}';

  /**
   * Get employee by id for PowerBI.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getEmployeesByIdForPbi()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesByIdForPbi$Response(params: {
    id: number;

  }): Observable<EmployeePbi> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(PowerBiReportService.GetEmployeesByIdForPbiPath, 'get');
    if (params) {
      req.path('id', params.id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as EmployeePbi;
      })
    );
  }

  /**
   * Get employee by id for PowerBI.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getEmployeesByIdForPbi$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesByIdForPbi(params: {
    id: number;

  }): Observable<EmployeePbi> {

    return this.getEmployeesByIdForPbi$Response(params).pipe(
      map(r => r as EmployeePbi)
    );
  }

  /**
   * Path part for operation getAllEmployeesForPbi
   */
  static readonly GetAllEmployeesForPbiPath = '/pbi/employees';

  /**
   * List of all employees for PowerBI.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getAllEmployeesForPbi()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEmployeesForPbi$Response(params?: {

  }): Observable<Array<EmployeePbi>> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(PowerBiReportService.GetAllEmployeesForPbiPath, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Array<EmployeePbi>;
      })
    );
  }

  /**
   * List of all employees for PowerBI.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllEmployeesForPbi$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEmployeesForPbi(params?: {

  }): Observable<Array<EmployeePbi>> {

    return this.getAllEmployeesForPbi$Response(params).pipe(
      map(r => r as Array<EmployeePbi>)
    );
  }

}
