/* tslint:disable */
import { Injectable } from '@angular/core';
import { SwaggerSpacesDefinitionsBaseService } from '../swagger-spaces-definitions-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { SwaggerSpacesDefinitionsRequestBuilder } from '../swagger-spaces-definitions-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HomeData } from '../models/home-data';


/**
 * Operations about home employee
 */
@Injectable({
  providedIn: 'root',
})
export class HomeService extends SwaggerSpacesDefinitionsBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getEmployeeV2
   */
  static readonly GetEmployeeV2Path = '/employee';

  /**
   * Get user data (Employee, warningState, active survey, space data) (v2).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getEmployeeV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeV2$Response(params?: {

  }): Observable<HomeData> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(HomeService.GetEmployeeV2Path, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as HomeData;
      })
    );
  }

  /**
   * Get user data (Employee, warningState, active survey, space data) (v2).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getEmployeeV2$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeV2(params?: {

  }): Observable<HomeData> {

    return this.getEmployeeV2$Response(params).pipe(
      map(r => r as HomeData)
    );
  }

}
