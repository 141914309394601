import { Observable } from 'rxjs';
import { retryWhen } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { RetryParams, restRetryStrategy, mustRetryFn } from '@config/rest-retry-strategy.config';
import { ConfigService } from '@core/services/config/config.service';
import { CustomLoggerService } from '@core/services/logger/logger.service';
import { UtilsService } from '@core/services/utils/utils.service';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  private envConfig;
  private retryConfig: RetryParams;

  constructor(
    private readonly config: ConfigService,
    private readonly logger: CustomLoggerService,
    private readonly utils: UtilsService
  ) {
    this.envConfig = this.config.config;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.retryConfig = { // Generic retry strategy. Can be overwritten by specific endpoint retry strategy
      enable: this.envConfig.app.properties.retry.enable,
      maxAttempts: this.envConfig.app.properties.retry.maxAttempts,
      interval: this.envConfig.app.properties.retry.interval,
      errorCodes: this.envConfig.app.properties.retry.errorCodes
    };

    this.mapRetryOptions(request, this.retryConfig);

    this.logger.log(`This should be the combined retry config: \n ${JSON.stringify(this.retryConfig)} \n for request url: ${request.url}`);
    const { mustRetry } = this;
    return next.handle(request)
      .pipe(
        retryWhen(
          restRetryStrategy(
            {
              enable: this.retryConfig.enable,
              maxAttempts: this.retryConfig.maxAttempts,
              interval: this.retryConfig.interval,
              mustRetry
            }
          )
        )
      );
  }

  mapRetryOptions = (request, retryConfig) => {
    const requestEndpointMatched = this.utils.matchRequestEndpoint(request);
    const generalRetryEnabled = retryConfig.enable;
    const enableLiteral = 'enable';
    let prop;
    if (requestEndpointMatched) {
      for (prop in retryConfig) {
        if (prop !== enableLiteral && requestEndpointMatched.retryOptions && requestEndpointMatched.retryOptions[prop]) {
          this.retryConfig[prop] = requestEndpointMatched.retryOptions[prop];
        }
      }
      this.retryConfig[enableLiteral] = generalRetryEnabled && requestEndpointMatched.retryOptions ?
        requestEndpointMatched.retryOptions[enableLiteral] : false;
    }
  }

  private mustRetry: mustRetryFn = error => this.retryConfig.errorCodes.includes(error.status);
}
