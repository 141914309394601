  <div class="item-card-data">
    <div class="row" (click)="assignCard(card)">
      <div class="col-md-1 col-sm-6">
        <div class="rounded-circle">
          <img class="img-fluid" src="assets/img/icon-user.png" alt="" />
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="label-txt">
          {{ "SPACE_ALLOCATION.USER_ID" | translate }}
        </div>
        <div class="breakword">
          {{card.employee.mail}}
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="label-txt">
          {{ "SPACE_ALLOCATION.NAME" | translate }}
        </div>
        <div>
          {{card.employee.name}}
        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="label-txt">
          {{ "SPACE_ALLOCATION.OCCUPANCY_DATE" | translate }}
        </div>
        <div>
          {{card.day | date: 'dd/MM/yyyy' }}
        </div>
      </div>

      <div class="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
        <div class="icon-width-control">
          <i class="icon-accessibility-on" *ngIf="disabled"></i>
        </div>
        <div class="icon-width-control">
          <i class="icon-car" *ngIf="card.type === 'PARKING_PLACE'" ></i>
        </div>
        <i class="icon-monitor" ></i>
      </div>

      <div *ngIf="selectTab.text === 'SPACE_ALLOCATION.ALLOCATED_USERS'" (click)="selectCard()" class="col-md-1 col-sm-6 border-left d-flex align-items-center justify-content-center ">
        <i [ngClass]="showData ? 'icon-arrow-up' : 'icon-arrow-down'"></i>
      </div>
    </div>
  </div>
  <!-- BLUE DROPDOWN DETAIL -->
  <div class="item-card-dropinfo" *ngIf="showData && selectTab.text === 'SPACE_ALLOCATION.ALLOCATED_USERS'" >
    <div class="row pr-4">
      <div class="col-md-10 offset-md-1 col-sm-6 bg-blue">
        <div class="row">
          <!-- MONITOR SPACE -->
          <div class="col-md-4 offset-md-1">
            <div class="d-flex" *ngIf="card.workplace">
              <i class="icon-monitor" ></i>
              <div class="d-flex flex-column" >
                <div class="t-neutral-black">{{card.workplace.name}}</div >
                <div>
                  <label [translate]="'SPACE_ALLOCATION.BUILDING'"></label>
                  {{card.workplace.building}}
                </div>
                <div>
                  <label [translate]="'SPACE_ALLOCATION.FLOOR'">
                  </label>
                  {{card.workplace.floor}}
                </div>
              </div>
            </div>
          </div>
          <!-- PARKING SPACE -->
          <div class="col-md-4 offset-md-2">
            <div class="d-flex" *ngIf="card.parkingplace">
              <i class="icon-car" ></i>
              <div class="d-flex flex-column">
                <div class="t-neutral-black">{{card.parkingplace.name}}</div>
                <div>
                  <label [translate]="'SPACE_ALLOCATION.BUILDING'"></label>
                  {{card.parkingplace.building}}
                </div>
                <div>
                  <label [translate]="'SPACE_ALLOCATION.FLOOR'">
                  </label>
                  {{card.parkingplace.floor}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
