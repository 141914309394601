/* tslint:disable */
import { Injectable } from '@angular/core';
import { SwaggerSpacesDefinitionsBaseService } from '../swagger-spaces-definitions-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { SwaggerSpacesDefinitionsRequestBuilder } from '../swagger-spaces-definitions-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PageableRequest } from '../models/pageable-request';
import { SpacePageResponse } from '../models/space-page-response';


/**
 * Operations about floors
 */
@Injectable({
  providedIn: 'root',
})
export class FloorsService extends SwaggerSpacesDefinitionsBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listSpace
   */
  static readonly ListSpacePath = '/spaces/floor/{Id}/space';

  /**
   * All spaces of a floor.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `listSpace()` instead.
   *
   * This method doesn't expect any request body.
   */
  listSpace$Response(params: {
    Id: number;

    /**
     * Pagination and sort
     */
    pagination?: PageableRequest;

  }): Observable<SpacePageResponse> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(FloorsService.ListSpacePath, 'get');
    if (params) {
      req.path('Id', params.Id, {});
      req.query('pagination', params.pagination, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as SpacePageResponse;
      })
    );
  }

  /**
   * All spaces of a floor.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `listSpace$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  listSpace(params: {
    Id: number;

    /**
     * Pagination and sort
     */
    pagination?: PageableRequest;

  }): Observable<SpacePageResponse> {

    return this.listSpace$Response(params).pipe(
      map(r => r as SpacePageResponse)
    );
  }

}
