import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '@environments/environment';

// Core
import { CoreModule } from '@core/core.module';

// Shell
import { ShellModule } from '@shell/shell.module';

// Ionic
import { IonicArqModule } from '@app/ionic.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CoreModule.forRoot(),
    IonicArqModule.forRoot(),
    ShellModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
