import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from '@core/services/utils/utils.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslationsService } from '@app/core/services/translations/translations.service';

export interface TextWithParam {
  txtVar: string;
  txtParamName: string;
  txtParamValue: string;
}

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnInit {
  @Input() secondTitle: string;
  @Input() firstTitle: string;
  @Input() text: string;
  @Input() firstButton ? = null;
  @Input() secondButton ? = null;
  @Input() iconToShow ? = null;
  @Input() textWithParams: TextWithParam[] = [];
  @Input() selectData: boolean = false;
  @Input() lockedDays: [];
  @Input() hasWeekDays = false;
  @Input() hasWeekendDays = false;
  @Input() validationStartDateEndDate15DaysInterval = true;
  @Input() validationStartDate15DaysLater = false;
  dateForm: FormGroup;
  weekDaySelected = [];
  weekdayNoSelected = false;
  weekDays = [];
  textArgs = [];

  get startDate() {
    return this.dateForm.get('startDate');
  }

  get endDate() {
    return this.dateForm.get('endDate');
  }

  constructor(
    private modalController: ModalController,
    private translateService: TranslationsService,
    private utilsService: UtilsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.hasWeekDays && this.setWeekDays();
    this.manageExtraParams();

    if (this.selectData) {
      this.initDateForm();
    }
  }

  setWeekDays() {
    const allWeekDays = [
      { description: this.translateService.instant('MODAL.DATEPICKER.MONDAY'), value: 'monday' },
      { description: this.translateService.instant('MODAL.DATEPICKER.TUESDAY'), value: 'tuesday' },
      { description: this.translateService.instant('MODAL.DATEPICKER.WEDNESDAY'), value: 'wednesday' },
      { description: this.translateService.instant('MODAL.DATEPICKER.THURSDAY'), value: 'thursday' },
      { description: this.translateService.instant('MODAL.DATEPICKER.FRIDAY'), value: 'friday' },
      { description: this.translateService.instant('MODAL.DATEPICKER.SATURDAY'), value: 'saturday' },
      { description: this.translateService.instant('MODAL.DATEPICKER.SUNDAY'), value: 'sunday' }
    ];
    const size = this.hasWeekendDays ? 7 : 5;

    for (let i = 0; i < size; i++) {
      this.weekDays.push(allWeekDays[i]);
    }
  }

  checkDay(day) {
    const index = this.weekDaySelected.indexOf(day);

    if (index !== -1) {
      this.weekDaySelected.splice(index, 1);
    } else {
      this.weekDaySelected.push(day);
    }
  }

  async dismissModal(action?) {
    if (this.selectData) {
      // tslint:disable-next-line:prefer-const
      let params = {
        dismissed: true,
        info: {
          actionButton: action ? action : null,
          dateFrom: this.dateForm.get('startDate').value,
          dateTo: this.dateForm.get('endDate').value
        }
      };

      if (this.hasWeekDays) {
        // tslint:disable-next-line:no-string-literal
        params.info['weekDays'] = this.weekDaySelected;
        this.weekdayNoSelected = this.weekDaySelected.length === 0 ? true : false;
      }

      await this.modalController.dismiss(params);

    } else {
      await this.modalController.dismiss({
        dismissed: true,
        info: {
          actionButton: action ? action : null
        }
      });
    }
  }

  private manageExtraParams(): void {
    let newTxtWithParam;

    for (const text of this.textWithParams) {
      newTxtWithParam = {};
      Object.defineProperty(newTxtWithParam, text.txtParamName, {
        enumerable: true,
        configurable: false,
        writable: false,
        value: text.txtParamValue
      });

      this.textArgs.push(this.utilsService.deepCopy(newTxtWithParam));
    }
  }

  private initDateForm(): void {
    const validations = [];
    validations.push(this.utilsService.checkStartDateEndDate('startDate', 'endDate'));
    // tslint:disable-next-line:max-line-length
    this.validationStartDateEndDate15DaysInterval && validations.push(this.utilsService.checkStartDateEndDate15DaysInterval('startDate', 'endDate'));
    // tslint:disable-next-line:max-line-length
    if (this.validationStartDate15DaysLater) {
      validations.push(this.utilsService.checkStartDateEndDate15DaysInterval('startDate', 'endDate', true));
      validations.push(this.utilsService.checkLockedDays('startDate', 'endDate', this.lockedDays));
    }

    this.dateForm = this.formBuilder.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required]
    }, {
      validator: validations
    });
  }
}
