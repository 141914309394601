import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from '@core/services/translations/translations.service';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {
  private yes: string = 'BUTTON.YES';
  private no: string = 'BUTTON.NO';

  constructor(private  translationsService: TranslationsService) {
    this.yes = this.translationsService.instant(this.yes);
    this.no = this.translationsService.instant(this.no);
  }

  transform(value: any, ...args: any[]): any {
    return value ? this.yes : this.no;
  }
}
