import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { RequestsService, SpacesService, FloorsService, ReservationsService } from '@app/core/api/SWAGGER-SPACES-DEFINITIONS/services';
import { SpaceType, WaitingListPageResponse } from '@app/core/api/SWAGGER-SPACES-DEFINITIONS/models';
import { LockSpacesService } from '@app/core/api/SWAGGER-SPACES-DEFINITIONS/services/lock-spaces.service';

@Injectable({
  providedIn: 'root'
})
export class SpacePaginationService {

  spacesList: BehaviorSubject<WaitingListPageResponse> = new BehaviorSubject<WaitingListPageResponse>({});
  parkingList: BehaviorSubject<WaitingListPageResponse> = new BehaviorSubject<WaitingListPageResponse>({});
  parkingLockList: BehaviorSubject<WaitingListPageResponse> = new BehaviorSubject<WaitingListPageResponse>({});
  searchParams = {
    page: 0,
    size: 0
  };
  searchParamsPark = {
    page: 0,
    size: 0
  };
  searchParamsLockPark = {
    page: 0,
    size: 0
  };
  constructor(
    private requestService: RequestsService,
    private spacesService: SpacesService,
    private floorService: FloorsService,
    private spaceLockedService: LockSpacesService,
    private reservationsService: ReservationsService
  ) {}

  hasMorePages(): boolean {
    const assignsPage = this.spacesList.getValue();

    if (assignsPage.last !== undefined) {
      return !assignsPage.last;
    }
  }

  hasMorePagesPark(): boolean {
    const assignParkPage = this.parkingList.getValue();

    if (assignParkPage.last !== undefined) {
      return !assignParkPage.last;
    }
  }

  getParkingList(): WaitingListPageResponse {
    return this.parkingList.getValue();
  }

  hasMorePagesLockPark(): boolean {
    const assignParkPage = this.parkingLockList.getValue();

    if (assignParkPage.last !== undefined) {
      return !assignParkPage.last;
    }
  }

  getLockParkingList(): WaitingListPageResponse {
    return this.parkingLockList.getValue();
  }

  addSpacesToList(nextPageNumber: any): void {
    this.searchParams = {
      page: nextPageNumber,
      size: 20
    };
    const pagination = this.searchParams;
    this.requestService.listRequestTypeSpaceEmployee({pagination})
      .pipe(take(1))
      .subscribe((res) => {
        this.addNewSpacePage(res);
      });
  }

  addParkingToList(nextPageNumber: any): void {
    this.searchParamsPark = {
      page: nextPageNumber,
      size: 20
    };
    const pagination = this.searchParams;
    // this.requestService.listRequestAssignedEmployee({pagination})
    //   .pipe(take(1))
    //   .subscribe((res) => {
    //     this.addNewParkingPage(res);
    //   });
  }

  addSpacesToAssign(requestId, nextPageNumber: any, id, disabled): void {
    this.searchParams = {
      page: nextPageNumber,
      size: 20
    };
    const pagination = this.searchParams;
    this.spacesService.getRequestIdFreeSpaceByFloor({requestId, spaceType: SpaceType.WorkPlace, floorId: id, pagination})
      .pipe(take(1))
      .subscribe((res) => {
        this.addNewSpacePage(res);
      });
  }

  addParkingToAssgin(requestId, nextPageNumber: any, id, disabled): void {
    this.searchParamsPark = {
      page: nextPageNumber,
      size: 20
    };
    const pagination = this.searchParamsPark;
    this.spacesService.getRequestIdFreeSpaceByFloor({requestId , spaceType: SpaceType.ParkingPlace, floorId: id, pagination})
      .pipe(take(1))
      .subscribe((res) => {
        this.addNewParkingPage(res);
      });
  }

  addSpacesToFind(nextPageNumber: any, floorId): void {
    this.searchParams = {
      page: nextPageNumber,
      size: 20
    };
    const pagination = this.searchParams;
    const id = floorId;
    this.floorService.listSpace({ Id: id , pagination})
      .pipe(take(1))
      .subscribe((res) => {
        this.addNewSpacePage(res);
      });
  }

  addParkingToLocksList(nextPageNumber: any, idBuilding: number, idFloor: number): void {
    this.searchParamsLockPark = {
      page: nextPageNumber,
      size: 20
    };
    const pagination = this.searchParamsLockPark;
    this.spaceLockedService.listAllSpacesAndLock({ idBuilding, idFloor, pagination })
      .pipe(take(1))
      .subscribe((res) => {
        if (!this.parkingLockList.getValue().content) {
          this.setLockParkingList(res);
        } else {
          this.addNewLockParkingPage(res);
        }
      });
  }

  addReportsToList(dtFrom, dtTo, nextPageNumber: any): void {
    this.searchParams = {
      page: nextPageNumber,
      size: 20
    };
    const params = {
      dateFrom: dtFrom,
      dateTo: dtTo,
      pagination: this.searchParams
    };
    this.reservationsService.getReservationsByDaysByUserPageableV2(params)
      .pipe(take(1))
      .subscribe((res) => {
        this.addNewSpacePage(res);
      });
  }

  initPages(): void {
    this.searchParams.page = 0;
    this.searchParamsPark.page = 0;
    this.searchParamsLockPark.page = 0;
  }

  private addNewSpacePage(res): void {
    let updateSpacesList = this.spacesList.getValue();

    updateSpacesList.content.push(...res.content);

    const updatedList = updateSpacesList.content;

    updateSpacesList = {
      ...res,
      content: updatedList
    };

    this.setSpacesList(updateSpacesList);
  }

  private addNewParkingPage(res): void {
    let updateParkingList = this.parkingList.getValue();

    updateParkingList.content.push(...res.content);

    const updatedList = updateParkingList.content;

    updateParkingList = {
      ...res,
      content: updatedList
    };

    this.setParkingList(updateParkingList);
  }

  private addNewLockParkingPage(res): void {
    let updateParkingList = this.parkingLockList.getValue();

    updateParkingList.content.push(...res.content);

    const updatedList = updateParkingList.content;

    updateParkingList = {
      ...res,
      content: updatedList
    };

    this.setLockParkingList(updateParkingList);
  }

  addAssignedSpaceEmployee(dtFrom, dtTo, nextPageNumber: any): void {
    this.searchParamsPark = {
      size: 20,
      page: nextPageNumber,
    };
    const params = {
      dateFrom: dtFrom,
      dateTo: dtTo,
      pagination: this.searchParamsPark,
    };
    this.reservationsService
      .getAssignedSpaceEmployee(params)
      .pipe(take(1))
      .subscribe((res) => {
        this.addNewParkingPage(res);
      });
  }

  setSpacesList(spacesList) {
    this.spacesList.next(spacesList);
  }

  setParkingList(parkingList) {
    this.parkingList.next(parkingList);
  }

  setLockParkingList(parkingLockList) {
    this.parkingLockList.next(parkingLockList);
  }

  getNextPageNumber(): number {
    return this.searchParams.page + 1;
  }

  getNextPageNumberParking(): number {
    return this.searchParamsPark.page + 1;
  }

  getNextPageNumberLockParking(): number {
    return this.searchParamsLockPark.page + 1;
  }

}
