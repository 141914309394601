import { Injectable } from '@angular/core';
import { Endpoint } from '@core/models/endpoint/endpoint.interface';
import { HttpRequest } from '@angular/common/http';
import { ConfigService } from '@core/services/config/config.service';
import { Config } from '@core/models/envConfig/config.interface';
import { AbstractControl, FormGroup } from '@angular/forms';
import * as streamSaver from 'streamsaver';

@Injectable({
  providedIn: 'root'
})
/**
 * Commonly used utils and helpers
 */
export class UtilsService {

  private readonly envApis;
  private envConfig: Config | any;
  private separators = {
    dash: '-',
    slash: '/',
    bracket: '{',
    closeBracket: '}',
    doubleSlash: '//',
    slashAndBracket: '/{',
    closeSlashAndBracket: '}/'
  };

  constructor(
    private readonly config: ConfigService
  ) {
    this.envConfig = this.config.config;
    this.envApis = this.envConfig.app.rest;
  }

  // tslint:disable-next-line:align
  static  formatSpanishDateFromUS_String(date: string): string{
    // tslint:disable-next-line:whitespace
    return date.length === 10 ? date[8] + date[9] + date[7] + date[5] + date[6] + date[4] + date[0] + date[1] + date[2] + date[3] : date;
  }

  static getDateByRemovingDaysFromToday = (daysToRemove) => (d => new Date(d.setDate(d.getDate() - daysToRemove)))(new Date());

  static uppercaseWords = str => str.split(' ').map(w => `${ w.charAt(0).toUpperCase() }${ w.slice(1) }`).join(' ');

  static convertNgDateToString(value: { month: number; year: number; day: number }): string {
    const tempDate = new Date(Date.UTC(value.year, value.month - 1, value.day));
    const formatYmd = date => date.toISOString().slice(0, 10);

    return formatYmd(tempDate);
  }

  static convertDateToNgFormat(date: string): { month: number; year: number; day: number } {
    const dateNg = new Date(date);

    return {
      year: dateNg.getFullYear(),
      month: dateNg.getMonth() + 1,
      day: dateNg.getDate()
    };
  }

  static convertJavascriptDateToNgFormat(date: Date): { month: number; year: number; day: number } {
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    return {
      year: yyyy,
      month: mm,
      day: dd
    };
  }

  static convertDateToIsoFormat(inputDate: Date): string {
    return inputDate.toISOString().slice(0, 10);
  }

  static removeItemFromArrayByIndex: (itemsArray: any[], index: number) => any[] = (items, i) => items.splice(i, 1);

  /**
   * This method remove multiple spaces from the text input
   * @param formControlToClean: name of the form control to check
   */
  private static cleanTextValueFromMultipleSpaces(formControlToClean: AbstractControl): void {
    const cleanedTxt = formControlToClean.value.replace(/\s+/g, ' ');
    formControlToClean.patchValue(cleanedTxt, { onlySelf: true });
  }

  createRange: (min, max) => number[] = (min, max) => [...Array(max - min + 1).keys()].map(i => i + min);

  /**
   * Helper method for matching a request with its endpoint config block
   * @param request intercepted
   */
  matchRequestEndpoint = (request: HttpRequest<any>) => {
    const requestUrl = this.cleanRequestUrl(request.url);
    const api = this.matchApi(requestUrl, this.envApis);
    const url = {
      baseUrl: api ? api.baseUrl : ''
    };
    const endpoints: Endpoint = api ? api.endpoints : {};
    let endpoint: string;
    for (endpoint in endpoints) {
      if (this.requestUrlComparer(endpoint, endpoints, url.baseUrl, requestUrl)) {
        return { ...endpoints[endpoint], ...url };
      }
    }
    return false;
  }

  /**
   * Helper method for retrieving required config for interceptors
   * @param endpoint contains specific endpoint info and config
   * @param interceptorName (Loader, HttpCustom and Retry)
   */
  getInterceptorConfig = (endpoint, interceptorName: string) => {
    const interceptorOptionsField = {
      Loader: 'loaderOptions',
      HttpCustom: 'restOptions',
      Retry: 'retryOptions'
    };
    // In case somebody wants to extend the HttpCustom interceptor capabilities, you can pass
    // more options by combining the httpCustomAddons object with the block configuration
    const httpCustomAddons = {
      customProp: ''
    };
    return interceptorName === 'HttpCustom' ? { ...httpCustomAddons, ...endpoint[interceptorOptionsField[interceptorName]] }
      : endpoint[interceptorOptionsField[interceptorName]];
  }

  /**
   * Returns provided value as Int number
   * @param value: any
   */
  toInteger = (value: any): number => {
    return parseInt(`${ value }`, 10);
  }

  normalizeBoolean = (value) => {
    return value === 'true';
  }

  unregisterServiceWorkers() {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
       registration.unregister();
      }
    });
  }

  checkStartDateEndDate(dateFrom: string, dateTo: string): any {
    const compare = (a, b) => a.getTime() > b.getTime();

    return (group: FormGroup): { [key: string]: any } => {
      const formStartDate: any = group.controls[dateFrom].value ? group.controls[dateFrom].value : null;
      const formEndDate: any = group.controls[dateTo].value ? group.controls[dateTo].value : null;

      if (formStartDate && formEndDate && (formStartDate.day && formEndDate.day)) {
        const startDate = new Date(UtilsService.convertNgDateToString(formStartDate));
        const endDate = new Date(UtilsService.convertNgDateToString(formEndDate));

        if (compare(startDate, endDate)) {
          return {
            dates: true
          };
        }
      }
    };
  }

  checkMultipleSpacesInFormControl(formControlToClean: AbstractControl): void {
    if (formControlToClean.value.substr(-1) === ' ') {
      UtilsService.cleanTextValueFromMultipleSpaces(formControlToClean);
    }
  }

  checkStartDateEndDate15DaysInterval(dateFrom: string, dateTo: string, daysLater = false): any {
    const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

    // tslint:disable-next-line:cyclomatic-complexity
    return (group: FormGroup): { [key: string]: any } => {
      const formStartDate: any = group.controls[dateFrom].value ? group.controls[dateFrom].value : null;
      const formEndDate: any = group.controls[dateTo].value ? group.controls[dateTo].value : null;

      if (formStartDate && formEndDate && (formStartDate.day && formEndDate.day)) {
        const startDate = new Date(UtilsService.convertNgDateToString(formStartDate));
        const endDate = new Date(UtilsService.convertNgDateToString(formEndDate));
        const dateStartToDiff = daysLater ? new Date() : startDate;
        const dateEndTodiff = daysLater ? startDate : endDate;
        const daysBetweenStartAndEnd = diffDays(dateStartToDiff, dateEndTodiff);

        if (!daysLater && daysBetweenStartAndEnd > 15) {
          return { datesInterval: true };
        }

        if (daysLater && daysBetweenStartAndEnd < 15) {
          return { datesLater: true };
        }
      }
    };
  }

  checkLockedDays(dateFrom: string, dateTo: string, lockedDays: any): any {
    // tslint:disable-next-line:cyclomatic-complexity
    return (group: FormGroup): { [key: string]: any } => {
      const formStartDate: any = group.controls[dateFrom].value ? group.controls[dateFrom].value : null;
      const formEndDate: any = group.controls[dateTo].value ? group.controls[dateTo].value : null;

      if (formStartDate || formEndDate) {
        const startDayFormatted = formStartDate ? formStartDate.year + '-' + formStartDate.month + '-' + formStartDate.day : '';
        const endDayFormatted = formEndDate ? formEndDate.year + '-' + formEndDate.month + '-' + formEndDate.day : '';

        const startDateSelected = new Date(startDayFormatted).setHours(0, 0, 0, 0);
        const endDateSelected = new Date(endDayFormatted).setHours(0, 0, 0, 0);

        if (formStartDate) {
          // tslint:disable:prefer-for-of
          for (let i = 0; i < lockedDays.length; i++) {
            // tslint:disable:max-line-length
            if (new Date(lockedDays[i].startTime).setHours(0, 0, 0, 0) <= startDateSelected &&
                startDateSelected <= new Date(lockedDays[i].endTime).setHours(0, 0, 0, 0)) {
              return { datesLocked: true };
            }
          }
        }

        if (formEndDate) {
          // tslint:disable:prefer-for-of
          for (let i = 0; i < lockedDays.length; i++) {
            if (endDateSelected >= new Date(lockedDays[i].startTime).setHours(0, 0, 0, 0) &&
                endDateSelected <= new Date(lockedDays[i].endTime).setHours(0, 0, 0, 0)) {
              return { datesLocked: true };
            }
          }
        }

        if (formStartDate && formEndDate) {
          for (let i = 0; i < lockedDays.length; i++) {
            if ( startDateSelected <= new Date(lockedDays[i].startTime).setHours(0, 0, 0, 0)
               && endDateSelected >= new Date(lockedDays[i].endTime).setHours(0, 0, 0, 0)) {
                return { datesLocked: true };
            }
          }
        }
      }
    };
  }

  getEnumAsLiteralsArray: (inputEnum) => string[] = (inputEnum) => {
    const stringIsNumber = (value) => !isNaN(Number(value));

    return Object.keys(inputEnum)
      .filter(stringIsNumber)
      .map(key => inputEnum[key]);
  }

  deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  downloadBlob(downloadBlob: Blob, fileName: string, fileExtension: string) {
    const todayIso: string = UtilsService.convertDateToIsoFormat(new Date());

    try {
      const fileStream = streamSaver.createWriteStream(fileName + '_export_' + todayIso + fileExtension,
        {
          size: downloadBlob.size
        });

      // Some types definition are missing from Blob interface so use object access via string literals
      // tslint:disable-next-line
      const readableStream = downloadBlob['stream']();

      // Some types definition are missing from Window interface so use object access via string literals
      // tslint:disable-next-line
      if (window['WritableStream'] && readableStream.pipeTo) {
        return readableStream
          .pipeTo(fileStream);
      }

      const writer = fileStream.getWriter();
      const reader = readableStream.getReader();
      const pump = () =>
        reader
          .read()
          .then(res =>
            res.done ? writer.close() : writer.write(res.value).then(pump)
          );
      pump();
    } catch (e) {
      console.error(e);
    }
  }

  deleteDuplicatesElements = (arr, comp) => {
    // store the comparison  values in array
    const unique = arr ?  arr.map(e => e[comp])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter((e) => arr[e]).map(e => arr[e]) : [];

    return unique;
  }

  /**
   * Checks the provided endpoint name containing variables to find out if the
   * request url matches it
   *
   * @param isVariable: boolean
   * @param endpUrl: string
   * @param baseUrl: string
   * @param reqUrl: string
   */
  private checkVariableEndpoints = (isVariable: boolean, endpUrl: string, baseUrl: string, reqUrl: string): boolean => {
    if (!isVariable) {
      return false;
    }

    // First split the urls into chunks
    // Special handling for urls starting with 'protocol://' <- we need to remove that part
    // but considering that it may be the baseUrl
    const endpUrlChunks = this.urlChunks(endpUrl, baseUrl);
    const reqUrlChunks = this.urlChunks(reqUrl, baseUrl);

    // If they're not the same length they're not a match
    if (endpUrlChunks.length !== reqUrlChunks.length) {
      return false;
    }

    // Now check how many fields and variables the url contains and their positions
    // and try to determine if the request url satisfies them
    // The main idea will be that 'some/{variable}/url/provided' will match 'some/doe/url/provided'
    // by comparing them removing the variable part and separators -> 'someurlprovided' === 'someurlprovided'
    while (endpUrlChunks.some(this.isVariable)) {
      const index = endpUrlChunks.findIndex(this.isVariable);
      endpUrlChunks.splice(index, 1);
      reqUrlChunks.splice(index, 1);
    }

    return endpUrlChunks.join('') === reqUrlChunks.join('') ||
      (baseUrl + endpUrlChunks.join('') === reqUrlChunks.join(''));
  }

  /**
   * Removes possible parameters from request url so as to avoid issues on endpoint detection
   * @param requestUrl: string representing the original request url
   * @returns string: filtered url
   */
  private cleanRequestUrl = (requestUrl: string) => {
    const param = '?';
    return requestUrl.indexOf(param) !== -1 ? requestUrl.slice(0, requestUrl.indexOf(param)) : requestUrl;
  }

  /**
   * Returns endpoint check result against provided request url
   * @param endpoint: string
   * @param endpoints: Endpoint
   * @param baseUrl: string
   * @param requestUrl: string
   * @param variableEndpoint (optional): boolean
   */
  private compare = (endpoint: string, endpoints: Endpoint, baseUrl: string, requestUrl: string, variableEndpoint?: boolean) => {
    return endpoint ? endpoints[endpoint].url === requestUrl ||
      (baseUrl + endpoints[endpoint].url) === requestUrl ||
      requestUrl.indexOf(endpoints[endpoint].url) !== -1 ||
      requestUrl.indexOf(baseUrl + endpoints[endpoint].url) !== -1 ||
      this.checkVariableEndpoints(variableEndpoint, endpoints[endpoint].url, baseUrl, requestUrl) :
      false;
  }

  /**
   * Checks if provided string contains interpretable variables
   * @param fragment: string
   */
  private isVariable = (fragment: string): boolean => {
    const ind = '{';
    return fragment.indexOf(ind) !== -1;
  }

  /**
   * Searches for the api block containing the request endpoint and returns it
   * @param requestUrl: string
   * @param envApis: EnvRest api blocks
   * @return api: identified api block
   */
  private matchApi = (requestUrl: string, envApis) => {
    let api: string;
    const apis = envApis;
    for (api in apis) {
      if (this.requestUrlComparer('', apis[api].endpoints, apis[api].baseUrl, requestUrl)) {
        return apis[api];
      }
    }
    return false;
  }

  /**
   * Compares the request url against the available endpoints to find a match
   * @param endpoint: string
   * @param endpoints: Endpoint
   * @param baseUrl: string
   * @param requestUrl: string
   * @param variableEndpoint (optional): boolean
   */
  private requestUrlComparer = (endpoint: string, endpoints: Endpoint, baseUrl: string, requestUrl: string, variableEndpoint?: boolean) => {
    if (!endpoints || !Object.keys(endpoints).length) {
      return false;
    }
    // If no endpoint is provided means we're still looking for the api block, so we'll iterate through
    // all the endpoints for each api block until finding it
    if (!endpoint) {
      let endp: string;
      for (endp in endpoints) {
        if (this.requestUrlComparer(endp, endpoints, baseUrl, requestUrl, this.isVariable(endp))) {
          endpoint = endp;
          break;
        }
      }
    }
    return this.compare(endpoint, endpoints, baseUrl, requestUrl, variableEndpoint);
  }

  /**
   * Removes protocol part and returns the rest of the url as chunks
   * considering the char '/' as separator
   *
   * @param url: string
   * @param baseUrl (optional): string. Pending implementation
   */
  private urlChunks = (url: string, baseUrl?: string) => {
    return url.indexOf(this.separators.doubleSlash) !== -1 ?
      url.split(this.separators.doubleSlash)[1].split(this.separators.slash) :
      url.split(this.separators.slash);
  }
}
