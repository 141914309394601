import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmployeesService } from '@app/core/api/SWAGGER-SPACES-DEFINITIONS/services';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-filter-from-url',
  templateUrl: './filter-from-url.component.html',
  styleUrls: ['./filter-from-url.component.scss'],
})
export class FilterFromUrlComponent implements OnInit {

  @Input() dataByDefault: BehaviorSubject<any> = new BehaviorSubject({});
  @Input() placeholder: string;
  @Input() searchingText: string;
  @Input() searchFailedText: string;
  @Input() keyToShow: string;
  @Input() minCharsNumber = 3;
  @Input() errorMessage: string;
  @Output() selectedItem: EventEmitter<any> = new EventEmitter();

  model: any;
  showMessage = false;

  constructor(private employeesService: EmployeesService) { }

  ngOnInit(): void {
    this.dataByDefault.subscribe((data) => {
      this.model = data ? data : this.model;
    });
  }

  selectItem(ev) {
    if (ev.item) {
      this.selectedItem.emit(ev.item);
    }
  }

  formatter = (arr: any) => arr[this.keyToShow];

  cleanInput() {
    this.model = '';
    this.selectedItem.emit('');
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => {
        if (0 < term.length && term.length < this.minCharsNumber) {
          this.showMessage = true;
        } else {
          this.showMessage = false;

          if (term.length > 0) {
            return this.employeesService.listEmployeesV2({ name: term }).pipe(
              catchError(() => {
                return of([]);
              })
            );
          } else {
              this.selectedItem.emit('');
              return term;
          }
        }
      })
    )
}
