/* tslint:disable */
import { Injectable } from '@angular/core';
import { SwaggerSpacesDefinitionsBaseService } from '../swagger-spaces-definitions-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { SwaggerSpacesDefinitionsRequestBuilder } from '../swagger-spaces-definitions-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LockParkingDto } from '../models/lock-parking-dto';
import { PageableRequest } from '../models/pageable-request';
import { SpacesAndLockDto } from '../models/spaces-and-lock-dto';


/**
 * Operations about lock spaces
 */
@Injectable({
  providedIn: 'root',
})
export class LockSpacesService extends SwaggerSpacesDefinitionsBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listAllSpacesAndLock
   */
  static readonly ListAllSpacesAndLockPath = '/spaces/lock/{idBuilding}/{idFloor}';

  /**
   * All spaces and lock.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `listAllSpacesAndLock()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllSpacesAndLock$Response(params: {
    idBuilding: number;
    idFloor: number;

    /**
     * Pagination and sort
     */
    pagination?: PageableRequest;

  }): Observable<SpacesAndLockDto> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(LockSpacesService.ListAllSpacesAndLockPath, 'get');
    if (params) {
      req.path('idBuilding', params.idBuilding, {});
      req.path('idFloor', params.idFloor, {});
      req.query('pagination', params.pagination, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as SpacesAndLockDto;
      })
    );
  }

  /**
   * All spaces and lock.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `listAllSpacesAndLock$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  listAllSpacesAndLock(params: {
    idBuilding: number;
    idFloor: number;

    /**
     * Pagination and sort
     */
    pagination?: PageableRequest;

  }): Observable<SpacesAndLockDto> {

    return this.listAllSpacesAndLock$Response(params).pipe(
      map(r => r as SpacesAndLockDto)
    );
  }

  /**
   * Path part for operation deleteLockParking
   */
  static readonly DeleteLockParkingPath = '/spaces/lock/{id}';

  /**
   * Delete a lock space.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `deleteLockParking()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLockParking$Response(params: {
    id: number;

  }): Observable<void> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(LockSpacesService.DeleteLockParkingPath, 'delete');
    if (params) {
      req.path('id', params.id, {});
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Delete a lock space.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `deleteLockParking$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLockParking(params: {
    id: number;

  }): Observable<void> {

    return this.deleteLockParking$Response(params).pipe(
      map(r => r as void)
    );
  }

  /**
   * Path part for operation modifySpace
   */
  static readonly ModifySpacePath = '/spaces/lock';

  /**
   * Modify a space.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `modifySpace()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifySpace$Response(params: {

    /**
     * New space lock
     */
    body: LockParkingDto
  }): Observable<LockParkingDto> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(LockSpacesService.ModifySpacePath, 'put');
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as LockParkingDto;
      })
    );
  }

  /**
   * Modify a space.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `modifySpace$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifySpace(params: {

    /**
     * New space lock
     */
    body: LockParkingDto
  }): Observable<LockParkingDto> {

    return this.modifySpace$Response(params).pipe(
      map(r => r as LockParkingDto)
    );
  }

  /**
   * Path part for operation addLockParking
   */
  static readonly AddLockParkingPath = '/spaces/lock';

  /**
   * Add space lock.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `addLockParking()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLockParking$Response(params: {

    /**
     * Space lock.
     */
    body: LockParkingDto
  }): Observable<LockParkingDto> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(LockSpacesService.AddLockParkingPath, 'post');
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as LockParkingDto;
      })
    );
  }

  /**
   * Add space lock.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `addLockParking$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLockParking(params: {

    /**
     * Space lock.
     */
    body: LockParkingDto
  }): Observable<LockParkingDto> {

    return this.addLockParking$Response(params).pipe(
      map(r => r as LockParkingDto)
    );
  }

}
