export enum WizardStep {
  SurveyInfo,
  SurveyStructure
}

export enum QuestionType {
  input,
  boolean,
  rating
}

export enum QuestionTypeText {
  FreeText = 'SURVEY.FORM.FREE_TEXT',
  YesNo = 'SURVEY.FORM.YES_NO',
  Rating = 'SURVEY.FORM.RATING'
}

export enum QuestionStyle {
  thumbs,
  slider,
  emojis,
  default
}
