/**
 * Single file for all the app's internal paths
 */
export const AppUrls = {

  // Config TO-DO: define a remote url for config fetch and maybe set a default for offline purposes
  Config: 'config/config.json',
  StreamSaverLibrary: 'config/mitm.html',

  // General
  App: '',
  AppRoot: '/',

  // Home
  AppHome: 'home',
  AppHomeRoot: '',

  // Login
  AppLogin: 'login',
  AppLoginRoot: '',

  // Spaces
  AppSpaceManager: 'space-manager',
  AppSpaceManagerRoot: '',

  // SpacesComponent
  AppSpaces: 'spaces',
  AppSpacesRoot: '',

  // Asign Spaces
  AppAssignSpaces: 'space-assign',
  AppAssignSpacesRoot: '',

  // Spaces locked
  AppSpacesLocked: 'spaces-locked',
  AppSpacesLockedRoot: '',

  // Surveys
  AppSurveyManager: 'survey-manager',
  AppSurveyWizardCreation: 'create-survey',
  AppSurveyList: 'list-surveys',
  AppSurveyManagerRoot: '',
  AppSurveyReports: 'survey-reports',

  // Reports
  AppReports: 'reports',
  AppReportsRoot: '',

  // Reports powerbi
  AppReportsPowerBi: 'reports-powerbi',
  AppReportsPowerBiRoot: ''
};
