import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaces'
})
export class SpacesPipe implements PipeTransform {

  transform(cards: any, floor: any, ): any {
    let array = cards;
    if (floor && array) {
      array = array.filter(element => {
        return floor.id === element.floorId;
      });
    }
    return array;
  }

}
