import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { Embed, IEmbedConfiguration, models, service as PBIService } from 'powerbi-client';
import { IFilter } from 'powerbi-models';

@Component({
    selector: 'app-powerbi',
    templateUrl: './power-bi.component.html',
    styleUrls: ['./power-bi.component.scss']
})
export class PowerBIComponent implements OnInit, OnChanges {

    component: Embed;
    @Input() accessToken: string;
    @Input() tokenType: string;
    @Input() embedUrl: string;
    @Input() type: string;
    @Input() filters: object[];

    @Output() embedded: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('pbiFrame', { static: true }) pbiFrame: ElementRef;

    constructor(@Inject('PowerBIService') public powerBIService: PBIService.Service) { }

    ngOnInit() {
        const { accessToken, tokenType, embedUrl, type } = this;

        const config: IEmbedConfiguration = {
            accessToken,
            tokenType: this.getTokenType(tokenType),
            embedUrl,
            type,
            // filters: this.buildFilters(this.filters),
            // settings: {
            //     filterPaneEnabled: false,
            //     navContentPaneEnabled: false
            // }
        };

        if (this.validateOptions(accessToken, embedUrl)) {
            this.embed(this.pbiFrame.nativeElement, config);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { accessToken, tokenType, embedUrl, type } = changes;

        if ((accessToken?.previousValue === accessToken?.currentValue) ||
            (embedUrl && embedUrl.previousValue === embedUrl.currentValue)) {
            return;
        }

        if (this.validateOptions(accessToken?.currentValue, embedUrl?.currentValue)) {
            const config: IEmbedConfiguration = {
                accessToken: accessToken ? accessToken.currentValue : this.accessToken,
                tokenType: tokenType ? this.getTokenType(tokenType.currentValue) : this.getTokenType(this.tokenType),
                embedUrl: this.embedUrl,
                type: type ? type.currentValue : this.type,
                // filters: this.buildFilters(this.filters),
                // settings: {
                //     filterPaneEnabled: false,
                //     navContentPaneEnabled: false
                // }
            };

            this.embed(this.pbiFrame.nativeElement, config);
        } else if (this.component) {
            this.reset(this.pbiFrame.nativeElement);
        }

    }

    validateOptions(accessToken: string, embedUrl: string): boolean {
        if (!accessToken || !embedUrl) {
            return false;
        }
        return true;
    }

    getTokenType(tokenType: string): models.TokenType {
        if (!tokenType || tokenType.length < 0) {
            return models.TokenType.Aad;
        } else {
            tokenType = tokenType.charAt(0).toUpperCase() + tokenType.toLowerCase().slice(1);
            return models.TokenType[tokenType];
        }
    }

    buildFilters(filters: object[]): IFilter[] {
        const filtersPBI: IFilter[] = [];

        // filters.forEach(filter => {
        //     const filterPBI = new BasicFilter({ table: filter['table'], column: filter['column'] }, 'In', filter['value']);
        //     filtersPBI.push(filterPBI.toJSON());
        // });

        return filtersPBI;
    }

    embed(element: HTMLElement, config: IEmbedConfiguration) {
        this.component = this.powerBIService.embed(element, config);
        this.component.off('loaded');
        this.component.on('error', (event) => {
            console.log(event);
        });
        this.embedded.emit((this.component as any));
    }

    reset(element: HTMLElement) {
        this.powerBIService.reset(element);
        this.component = null;
    }
}
