<!-- CONTAINER FILTER -->
<div class="filter-container">
  <div class="box p-3">
    <h4>Filtros de búsqueda</h4>
    <div class="item-card-form" > 
      <form class="row" [formGroup]="filterForm">
        <div class="col-md-3 col-sm-6">
          <div class="form-group">
            <label class="mandatory-field" for="formGroupExampleInput"
              >{{ "SPACE.BUILDING" | translate}}</label
            >
            <ng-select
            class="customSelect"
            [searchable]="false"
            notFoundText="{{ 'SPACE.NO_DATA' | translate }}"
            placeholder=""   
            formControlName="building"        
            [markFirst]="true"
            (change)="selectBuilding($event)"
            >
              <ng-option *ngFor="let building of buildings" [value]="building">
                {{building.name}}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <!-- <div class="col-md-2 col-sm-6">
          <div class="form-group">
            <label class="mandatory-field" for="formGroupExampleInput"
              >{{ "SPACE.TYPE" | translate}}</label
            >
            <ng-select
            class="customSelect"
            [searchable]="false"
            placeholder=""
            formControlName="type"    
            [markFirst]="true">
            <ng-option value="">Todos</ng-option>     
              <ng-option *ngFor="let type of typespace" [value]="type">
                {{type}}
              </ng-option>   
            </ng-select>
          </div>
        </div> -->
        <div class="col-md-2 col-sm-6">
          <div class="form-group">
            <label class="mandatory-field" for="formGroupExampleInput"
              >{{ "SPACE.FLOOR" | translate}}</label
            >
            <ng-select
            class="customSelect"
            [searchable]="false"
            placeholder=""
            notFoundText="{{ 'SPACE.CHOOSE_BUILDING' | translate }}"
            formControlName="floor"          
            [markFirst]="true"
            (change)="selectFloor($event)"
            >
              <ng-option *ngFor="let floor of floors" [value]="floor">
                {{floor.name}}
              </ng-option> 
            </ng-select>
          </div>
        </div>
        <!-- <div class="col-md-2 col-sm-6">
          <div class="form-group">
            <label class="mandatory-field" for="formGroupExampleInput"
              >{{ "SPACE.STATUS" | translate}}</label
            >
            <ng-select
            class="customSelect"
            [searchable]="false"
            placeholder=""    
            formControlName="state"       
            [markFirst]="true">
            <ng-option value="">Todos</ng-option>     
              <ng-option *ngFor="let state of status" [value]="state">
                {{state}}
              </ng-option>
            </ng-select>
          </div>
        </div> -->
        <!-- <div class="col-md-3 col-sm-6">
          <div class="form-group">
            <label class="mandatory-field" for="formGroupExampleInput"
              >{{ "SPACE.ACCESIBILITY" | translate}}</label
            >
            <ng-select
            class="customSelect"
            [searchable]="false"
            placeholder=""
            formControlName="access"      
            [markFirst]="true">
            <ng-option value="">Todos</ng-option>     
            <ng-option *ngFor="let access of accessibility" [value]="access">
                {{access}}
              </ng-option>
            </ng-select>
          </div>
        </div> -->
      </form>
      <div class="btn-block border-top justify-content-center pb-0">
        <button type="button" class="btn btn-primary" id="buttonFilter" (click)="searchSpaces()" [disabled]="!filterForm.valid">
          {{ "BUTTON.SEARCH" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
