import { Injectable } from '@angular/core';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DynamicCustomDatepickerService extends NgbDatepickerConfig {

  constructor() {
    super();
    // this.markDisabled = { year: 2021, month: 9, day: 24 }, { year: 2021, month: 9 });
  }
}
