/* tslint:disable */
import { Injectable } from '@angular/core';
import { SwaggerSpacesDefinitionsBaseService } from '../swagger-spaces-definitions-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { SwaggerSpacesDefinitionsRequestBuilder } from '../swagger-spaces-definitions-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AvailabilityDateSpacesType } from '../models/availability-date-spaces-type';
import { PageableWithDisabledRequest } from '../models/pageable-with-disabled-request';
import { Space } from '../models/space';
import { SpaceCreation } from '../models/space-creation';
import { SpacePatch } from '../models/space-patch';
import { SpaceStatisticsType } from '../models/space-statistics-type';
import { SpaceType } from '../models/space-type';


/**
 * Operations about spaces
 */
@Injectable({
  providedIn: 'root',
})
export class SpacesService extends SwaggerSpacesDefinitionsBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createSpace
   */
  static readonly CreateSpacePath = '/spaces';

  /**
   * Create a space.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `createSpace()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSpace$Response(params: {

    /**
     * Space object that needs to be added to the store
     */
    body: SpaceCreation
  }): Observable<Space> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(SpacesService.CreateSpacePath, 'put');
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Space;
      })
    );
  }

  /**
   * Create a space.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `createSpace$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSpace(params: {

    /**
     * Space object that needs to be added to the store
     */
    body: SpaceCreation
  }): Observable<Space> {

    return this.createSpace$Response(params).pipe(
      map(r => r as Space)
    );
  }

  /**
   * Path part for operation getSpace
   */
  static readonly GetSpacePath = '/spaces/{Id}';

  /**
   * Get space data.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getSpace()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpace$Response(params: {
    Id: number;

  }): Observable<Space> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(SpacesService.GetSpacePath, 'get');
    if (params) {
      req.path('Id', params.Id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Space;
      })
    );
  }

  /**
   * Get space data.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getSpace$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getSpace(params: {
    Id: number;

  }): Observable<Space> {

    return this.getSpace$Response(params).pipe(
      map(r => r as Space)
    );
  }

  /**
   * Path part for operation deleteSpace
   */
  static readonly DeleteSpacePath = '/spaces/{Id}';

  /**
   * Delete a space.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `deleteSpace()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSpace$Response(params: {
    Id: number;

  }): Observable<void> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(SpacesService.DeleteSpacePath, 'delete');
    if (params) {
      req.path('Id', params.Id, {});
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Delete a space.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `deleteSpace$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSpace(params: {
    Id: number;

  }): Observable<void> {

    return this.deleteSpace$Response(params).pipe(
      map(r => r as void)
    );
  }

  /**
   * Path part for operation patchSpace
   */
  static readonly PatchSpacePath = '/spaces/{Id}';

  /**
   * Modify a space.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `patchSpace()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchSpace$Response(params: {
    Id: number;

    /**
     * Space object that needs to be modified
     */
    body: SpacePatch
  }): Observable<SpacePatch> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(SpacesService.PatchSpacePath, 'patch');
    if (params) {
      req.path('Id', params.Id, {});
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as SpacePatch;
      })
    );
  }

  /**
   * Modify a space.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `patchSpace$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchSpace(params: {
    Id: number;

    /**
     * Space object that needs to be modified
     */
    body: SpacePatch
  }): Observable<SpacePatch> {

    return this.patchSpace$Response(params).pipe(
      map(r => r as SpacePatch)
    );
  }

  /**
   * Path part for operation getNextDayFreeSpaceByBuilding
   */
  static readonly GetNextDayFreeSpaceByBuildingPath = '/spaces/{spaceType}/{buildingId}/building/free';

  /**
   * Retrieve the list of free spaces for next laboral date by building id.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getNextDayFreeSpaceByBuilding()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNextDayFreeSpaceByBuilding$Response(params: {

    /**
     * Pagination, sort and disabled
     */
    pagination?: PageableWithDisabledRequest;

    /**
     * reservation id
     */
    spaceType: SpaceType;
    buildingId: number;

  }): Observable<Space> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(SpacesService.GetNextDayFreeSpaceByBuildingPath, 'get');
    if (params) {
      req.query('pagination', params.pagination, {});
      req.path('spaceType', params.spaceType, {});
      req.path('buildingId', params.buildingId, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Space;
      })
    );
  }

  /**
   * Retrieve the list of free spaces for next laboral date by building id.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getNextDayFreeSpaceByBuilding$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getNextDayFreeSpaceByBuilding(params: {

    /**
     * Pagination, sort and disabled
     */
    pagination?: PageableWithDisabledRequest;

    /**
     * reservation id
     */
    spaceType: SpaceType;
    buildingId: number;

  }): Observable<Space> {

    return this.getNextDayFreeSpaceByBuilding$Response(params).pipe(
      map(r => r as Space)
    );
  }

  /**
   * Path part for operation getNextDayFreeSpaceByFloor
   */
  static readonly GetNextDayFreeSpaceByFloorPath = '/spaces/{spaceType}/{floorId}/floor/free';

  /**
   * Retrieve the list of free spaces for next laboral date by floor id.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getNextDayFreeSpaceByFloor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNextDayFreeSpaceByFloor$Response(params: {

    /**
     * Pagination, sort and disabled
     */
    pagination?: PageableWithDisabledRequest;

    /**
     * reservation id
     */
    spaceType: SpaceType;
    floorId: number;

  }): Observable<Space> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(SpacesService.GetNextDayFreeSpaceByFloorPath, 'get');
    if (params) {
      req.query('pagination', params.pagination, {});
      req.path('spaceType', params.spaceType, {});
      req.path('floorId', params.floorId, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Space;
      })
    );
  }

  /**
   * Retrieve the list of free spaces for next laboral date by floor id.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getNextDayFreeSpaceByFloor$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getNextDayFreeSpaceByFloor(params: {

    /**
     * Pagination, sort and disabled
     */
    pagination?: PageableWithDisabledRequest;

    /**
     * reservation id
     */
    spaceType: SpaceType;
    floorId: number;

  }): Observable<Space> {

    return this.getNextDayFreeSpaceByFloor$Response(params).pipe(
      map(r => r as Space)
    );
  }

  /**
   * Path part for operation getRequestIdFreeSpaceByBuilding
   */
  static readonly GetRequestIdFreeSpaceByBuildingPath = '/spaces/{requestId}/{spaceType}/{buildingId}/building/free';

  /**
   * Retrieve the list of free spaces for request id by building id.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getRequestIdFreeSpaceByBuilding()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestIdFreeSpaceByBuilding$Response(params: {
    requestId: number;

    /**
     * Pagination, sort and disabled
     */
    pagination?: PageableWithDisabledRequest;

    /**
     * reservation id
     */
    spaceType: SpaceType;
    buildingId: number;

  }): Observable<Space> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(SpacesService.GetRequestIdFreeSpaceByBuildingPath, 'get');
    if (params) {
      req.path('requestId', params.requestId, {});
      req.query('pagination', params.pagination, {});
      req.path('spaceType', params.spaceType, {});
      req.path('buildingId', params.buildingId, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Space;
      })
    );
  }

  /**
   * Retrieve the list of free spaces for request id by building id.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getRequestIdFreeSpaceByBuilding$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestIdFreeSpaceByBuilding(params: {
    requestId: number;

    /**
     * Pagination, sort and disabled
     */
    pagination?: PageableWithDisabledRequest;

    /**
     * reservation id
     */
    spaceType: SpaceType;
    buildingId: number;

  }): Observable<Space> {

    return this.getRequestIdFreeSpaceByBuilding$Response(params).pipe(
      map(r => r as Space)
    );
  }

  /**
   * Path part for operation getRequestIdFreeSpaceByFloor
   */
  static readonly GetRequestIdFreeSpaceByFloorPath = '/spaces/{requestId}/{spaceType}/{floorId}/floor/free';

  /**
   * Retrieve the list of free spaces for request id date by floor id.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getRequestIdFreeSpaceByFloor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestIdFreeSpaceByFloor$Response(params: {
    requestId: number;

    /**
     * Pagination, sort and disabled
     */
    pagination?: PageableWithDisabledRequest;

    /**
     * reservation id
     */
    spaceType: SpaceType;
    floorId: number;

  }): Observable<Space> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(SpacesService.GetRequestIdFreeSpaceByFloorPath, 'get');
    if (params) {
      req.path('requestId', params.requestId, {});
      req.query('pagination', params.pagination, {});
      req.path('spaceType', params.spaceType, {});
      req.path('floorId', params.floorId, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Space;
      })
    );
  }

  /**
   * Retrieve the list of free spaces for request id date by floor id.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getRequestIdFreeSpaceByFloor$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestIdFreeSpaceByFloor(params: {
    requestId: number;

    /**
     * Pagination, sort and disabled
     */
    pagination?: PageableWithDisabledRequest;

    /**
     * reservation id
     */
    spaceType: SpaceType;
    floorId: number;

  }): Observable<Space> {

    return this.getRequestIdFreeSpaceByFloor$Response(params).pipe(
      map(r => r as Space)
    );
  }

  /**
   * Path part for operation getSpacesStatisticsByAdmin
   */
  static readonly GetSpacesStatisticsByAdminPath = '/spaces/statistics';

  /**
   * get statistics of spaces by Admin.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getSpacesStatisticsByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpacesStatisticsByAdmin$Response(params?: {

  }): Observable<SpaceStatisticsType> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(SpacesService.GetSpacesStatisticsByAdminPath, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as SpaceStatisticsType;
      })
    );
  }

  /**
   * get statistics of spaces by Admin.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getSpacesStatisticsByAdmin$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getSpacesStatisticsByAdmin(params?: {

  }): Observable<SpaceStatisticsType> {

    return this.getSpacesStatisticsByAdmin$Response(params).pipe(
      map(r => r as SpaceStatisticsType)
    );
  }

  /**
   * Path part for operation getAvailableDatesV2
   */
  static readonly GetAvailableDatesV2Path = '/spaces/availableDates';

  /**
   * get available dates of spaces (v2).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getAvailableDatesV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableDatesV2$Response(params?: {

  }): Observable<AvailabilityDateSpacesType> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(SpacesService.GetAvailableDatesV2Path, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as AvailabilityDateSpacesType;
      })
    );
  }

  /**
   * get available dates of spaces (v2).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAvailableDatesV2$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableDatesV2(params?: {

  }): Observable<AvailabilityDateSpacesType> {

    return this.getAvailableDatesV2$Response(params).pipe(
      map(r => r as AvailabilityDateSpacesType)
    );
  }

}
