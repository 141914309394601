/* tslint:disable:variable-name */

import { Injectable } from '@angular/core';
import { ReservationsService } from '@core/api/SWAGGER-SPACES-DEFINITIONS/services/reservations.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { BuildingsService } from '@core/api/SWAGGER-SPACES-DEFINITIONS/services/buildings.service';
import { KeyValue, Space, SpacePatch } from '@core/api/SWAGGER-SPACES-DEFINITIONS/models';
import { tap } from 'rxjs/operators';
import { Building, Floor, SpaceEntity } from '@shared/models/space/space.interface';
import { FloorsService } from '@core/api/SWAGGER-SPACES-DEFINITIONS/services/floors.service';
import { SpacesService } from '@core/api/SWAGGER-SPACES-DEFINITIONS/services/spaces.service';
import { LockSpacesService } from '@app/core/api/SWAGGER-SPACES-DEFINITIONS/services';

@Injectable({
  providedIn: 'root'
})
export class SpaceLogicService {
  constructor(private reservationsService: ReservationsService,
              private buildingService: BuildingsService,
              private floorService: FloorsService,
              private spaceLockedService: LockSpacesService,
              private spacesService: SpacesService) {
  }

  private _buildings: BehaviorSubject<Building[]> = new BehaviorSubject<Building[]>([]);
  get buildings(): Building[] {
    return this._buildings.getValue();
  }

  set buildings(newBuildingList: Building[]) {
    this._buildings.next(newBuildingList);
  }

  private _buildingSelectedForSearch: BehaviorSubject<Building> = new BehaviorSubject<Building>(null);

  set buildingSelectedForSearch(value: Building) {
    this._buildingSelectedForSearch.next(value);
  }

  get buildingSelectedForSearch$(): Observable<Building> {
    return this._buildingSelectedForSearch.asObservable();
  }

  get buildings$(): Observable<Building[]> {
    return this._buildings.asObservable();
  }

  private _floors: BehaviorSubject<Floor[]> = new BehaviorSubject<Floor[]>([]);

  get floors(): Floor[] {
    return this._floors.getValue();
  }

  set floors(value: Floor[]) {
    this._floors.next(value);
  }

  private _spaces: BehaviorSubject<Space[]> = new BehaviorSubject<Space[]>([]);

  get spaces(): Space[] {
    return this._spaces.getValue();
  }

  set spaces(value: Space[]) {
    this._spaces.next(value);
  }

  private _spaceDetails: BehaviorSubject<SpaceEntity> = new BehaviorSubject<SpaceEntity>(null);

  get spaceDetails(): SpaceEntity {
    return this._spaceDetails.getValue();
  }

  set spaceDetails(value: SpaceEntity) {
    this._spaceDetails.next(value);
  }

  get spaces$(): Observable<Space[]> {
    return this._spaces.asObservable();
  }

  get floors$(): Observable<Floor[]> {
    return this._floors.asObservable();
  }

  getFloorsByBuildingId(params: { Id: number }): Observable<Array<KeyValue>> {
    return this.buildingService.spacesBuildingIdFloorsGet(params)
      .pipe(
        tap((res: Floor[]) => this.floors = res)
      );
  }

  getSpacesByFloorId(params: { Id: number, pagination}) {
    return this.floorService.listSpace(params)
      .pipe(
        tap((res) => this.spaces = res.content)
      );
  }

  downloadSpacesReport(dateFrom: string, dateTo: string): Observable<Blob> {
    return this.reservationsService.reportAssignedSpaceEmployeeV2({
      dateFrom,
      dateTo
    });
  }

  getSpaceById(param: { Id: number }): Observable<SpaceEntity> {
    return this.spacesService.getSpace(param)
      .pipe(
        tap((res: any) => this.spaceDetails = res)
      ) as Observable<SpaceEntity>;
  }

  patchSpace(param: { Id: any; body: any }): Observable<SpacePatch> {
    return this.spacesService.patchSpace(param);
  }

  getSpacesLocked(idBuilding, idFloor, pagination) {
    return this.spaceLockedService.listAllSpacesAndLock({ idBuilding, idFloor, pagination })
      .pipe(
        tap((res: any) => this.spaces = res.content)
      );
  }
}
