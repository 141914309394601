import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WizardStep } from '@shared/constants/survey/enums';
import { SurveysApiService } from '@core/api/BE-DIGITAL-SURVEYS-API/services/surveys-api.service';
import {
  BooleanQuestion,
  InputQuestion,
  RatingQuestion,
  Survey,
  SurveyCreationTimeHorizon,
  SurveyEdit,
  SurveysPage
} from '@core/api/BE-DIGITAL-SURVEYS-API/models';
import { UtilsService } from '@core/services/utils/utils.service';
import { EditForms, QuestionsEntity, SurveyFI } from '@shared/models/survey/survey.interface';
import { SurveysSearchParams } from '@core/api/BE-DIGITAL-SURVEYS-API/models/survey-search-params';
import { take, tap } from 'rxjs/operators';

class NewSurvey implements Survey {
  description: string;
  questions: Array<InputQuestion | BooleanQuestion | RatingQuestion>;
  timeHorizon: SurveyCreationTimeHorizon;
  title: string;

  constructor() {
    this.description = '';
    this.timeHorizon = {
      opensOn: '',
      closesOn: ''
    };
    this.title = '';
    this.questions = [];
  }
}

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  searchParams: SurveysSearchParams = {
    active: null,
    opensOn: null,
    closesOn: null,
    title: null,
    page: 1,
    size: null
  };
  wizardStep: BehaviorSubject<WizardStep> = new BehaviorSubject<WizardStep>(WizardStep.SurveyInfo);
  isQuestionsArrayEmpty: boolean = true;
  isNewQuestionCollapsed: boolean = true;
  noResultsAvailable: boolean = false;
  textNoResults: string = 'SURVEY.NO_SURVEYS';
  private surveysList: BehaviorSubject<SurveysPage> = new BehaviorSubject<SurveysPage>({});
  private newSurvey: BehaviorSubject<Survey | SurveyFI> = new BehaviorSubject<Survey>(new NewSurvey());

  constructor(private surveyApiService: SurveysApiService) {
  }

  patchSurveyById(params: { id: number; body: SurveyEdit }) {
    return this.surveyApiService.modifySurveyById(params);
  }

  /**
   * Set current survey with the one passed
   * @param newSurvey - Survey to set
   */
  setSurvey(newSurvey: Survey | SurveyFI) {
    this.isQuestionsArrayEmpty = !newSurvey.questions.length;
    this.newSurvey.next(newSurvey);
  }

  setSurveysList(surveysList: SurveysPage) {
    this.surveysList.next(surveysList);
  }

  /**
   *  Change current step in survey creation process
   * @param newStep - New step of the wizard
   */
  changeStep(newStep: WizardStep) {
    this.wizardStep.next(newStep);
  }

  /**
   * Return current survey
   * @returns The current survey value
   */
  getSurvey(): Survey | SurveyFI {
    return this.newSurvey.getValue();
  }

  /**
   * Return current survey as observable
   * @returns The current survey in the service as Observable
   */
  getSurveyAsObservable(): Observable<Survey | SurveyFI> {
    return this.newSurvey.asObservable();
  }

  /**
   * Return length of current questions array
   * @returns The length of the array
   */
  getQuestionLength(): number {
    const currentSurvey: Survey | SurveyFI = this.newSurvey.getValue();
    return currentSurvey.questions.length;
  }

  /**
   * Add new question to questions array
   * @param newQuestion - New question to add to the array
   */
  pushNewQuestion(newQuestion): void {
    const tmpSurvey: Survey | SurveyFI = this.getSurvey();

    tmpSurvey.questions.push(newQuestion);
    this.setSurvey(tmpSurvey);
  }

  /**
   * Update current survey with the new one
   * @returns An observable of type Survey
   */
  saveNewCurrentSurvey(): Observable<Survey> {
    this.cleanNotNecessaryFieldFromNewSurvey();

    return this.surveyApiService.createSurvey(
      {
        body: this.getSurvey() as Survey
      });
  }

  /**
   * Remove question by question ID (only during survey creation process)
   * @param questionId - Question ID of the question to update
   */
  removeQuestionById(questionId: number): void {
    const currentSurvey: Survey | SurveyFI = this.newSurvey.getValue();
    const elementIndexToRemove = currentSurvey.questions.findIndex(question => question.id === questionId);

    UtilsService.removeItemFromArrayByIndex(currentSurvey.questions, elementIndexToRemove);
    this.setSurvey(currentSurvey);
  }

  /**
   * Update passed question in questions list
   * @param questionUpdated - Question updated
   */
  updateQuestion(questionUpdated: QuestionsEntity): void {
    const currentSurvey = this.getSurvey() as Survey;
    let questionToUpdate = currentSurvey.questions.find(question => question.id === questionUpdated.id) as QuestionsEntity;

    questionToUpdate = questionUpdated;
    this.setSurvey(currentSurvey);
  }

  hasMorePages(): boolean {
    const surveysPage: SurveysPage = this.surveysList.getValue();

    if (surveysPage.last !== undefined) {
      return !surveysPage.last;
    }
  }

  addSurveysToList(nextPageNumber: any): void {
    this.searchParams = {
      ...this.searchParams,
      page: nextPageNumber
    };

    this.surveyApiService.findSurveys(this.searchParams)
      .pipe(take(1))
      .subscribe((res: SurveysPage) => {
        this.addNewSurveysPage(res);
      });
  }

  getNextPageNumber(): number {
    return this.searchParams.page + 1;
  }

  findSurveys(body?: SurveysSearchParams): Observable<SurveysPage> {
    if (body) {
      this.searchParams = {
        ...this.searchParams,
        opensOn: body.opensOn ? body.opensOn : null,
        closesOn: body.closesOn ? body.closesOn : null,
        title: body.title ? body.title : null,
        active: body.hasOwnProperty('active') ? body.active : true
      };
    }

    this.surveyApiService.findSurveys(body)
      .pipe(
        take(1),
        tap((res: SurveysPage) => this.noResultsAvailable = !res.content.length)
      )
      .subscribe((res: SurveysPage) => {
        this.setSurveysList(res);
      });

    return this.surveysList.asObservable();
  }

  resetNewSurveyWizard(): void {
    this.setSurvey(new NewSurvey());
    this.wizardStep = new BehaviorSubject<WizardStep>(WizardStep.SurveyInfo);
  }

  closeEditModeForAllQuestions(): void {
    const questions: QuestionsEntity[] = this.newSurvey.getValue().questions;

    questions.map(question => question.editMode = false);

    if (!this.isNewQuestionCollapsed) {
      this.isNewQuestionCollapsed = true;
    }
  }

  moveDownQuestion(questionIndex: number): void {
    const question: QuestionsEntity[] = this.getSurvey().questions as QuestionsEntity[];

    if ((question.length - 1) > questionIndex) {
      [question[questionIndex], question[questionIndex + 1]] = [question[questionIndex + 1], question[questionIndex]];
    }
  }

  moveUpQuestion(questionIndex: number): void {
    const question: QuestionsEntity[] = this.getSurvey().questions as QuestionsEntity[];

    if (questionIndex > 0) {
      [question[questionIndex - 1], question[questionIndex]] = [question[questionIndex], question[questionIndex - 1]];
    }
  }

  toggleNewQuestion(): void {
    this.isNewQuestionCollapsed = !this.isNewQuestionCollapsed;
  }

  downloadSurveyReportValues(surveyId: number): Observable<Blob> {
    return this.surveyApiService.getSurveySummary({
      surveyId
    });
  }

  downloadSurveyReportFreeText(surveyId: number, dateFrom: string, dateTo: string) {
    return this.surveyApiService.getSurveyInputAnswers({
      id: surveyId,
      dateFrom,
      dateTo
    });
  }

  private addNewSurveysPage(res: SurveysPage): void {
    let updatedSurveysPage = this.surveysList.getValue();

    updatedSurveysPage.content.push(...res.content);

    const updatedList = updatedSurveysPage.content;

    updatedSurveysPage = {
      ...res,
      content: updatedList
    };

    this.setSurveysList(updatedSurveysPage);
  }

  private cleanNotNecessaryFieldFromNewSurvey(): void {
    const surveyFI = this.getSurvey() as SurveyFI;

    surveyFI.questions.map((question) => {
      delete question.editMode;
    });

    this.setSurvey(surveyFI);
  }

  updateSurveyInList(surveyId: number, changedFields: EditForms): void {
    const surveyList = this.surveysList.getValue();
    const surveyToUpdate: Survey = surveyList.content.find(survey => survey.id === surveyId);
    const surveyToUpdateIndex: number = surveyList.content.findIndex(survey => survey.id === surveyId);
    let surveyUpdated;

    surveyUpdated = {
      ...surveyToUpdate,
      ...changedFields
    };
    surveyList.content[surveyToUpdateIndex] = surveyUpdated;
    this.setSurveysList(surveyList);
  }
}
