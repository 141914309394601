import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { AppUrls } from '@config/app-urls.config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (this.auth.isLoggedIn$.value) {
      return true;
    } else {
      return new Observable<boolean>((observer) => {
        this.auth.oauthGetToken()
          .then(() => {
            observer.next(true);
            observer.complete();
          })
          .catch(() => {
            this.router.navigate([AppUrls.AppLogin]);
            observer.next(false);
            observer.complete();
          });
      });
    }
  }
}
