import { Injectable } from '@angular/core';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() {}

  /**
   * Retrieves the storage data associated with the provided key
   * @param key: string
   */
  get(key: string): any {
    return this.fromStorage(sessionStorage.getItem(key));
  }

  /**
   * Sets the provided data using the provided key as reference on storage
   * @param key: string
   * @param value: any, and it will be stringified prior to be stored
   */
  set(key: string, value: any) {
    sessionStorage.setItem(key, this.toStorage(value));
  }

  toStorage(data: any): string{
    return JSON.stringify(data);
  }

  fromStorage(data: string): any {
    return JSON.parse(data);
  }

  /**
   * Removes storage data associated to given key
   * @param key: string
   */
  remove(key: string) {
    sessionStorage.removeItem(key);
  }

  /**
   * Clears all the data currently on storage
   */
  clear() {
    sessionStorage.clear();
  }
}
