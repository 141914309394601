import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-block-bottom',
  templateUrl: './btn-block-bottom.component.html',
  styleUrls: ['./btn-block-bottom.component.scss'],
})
export class BtnBlockBottomComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
