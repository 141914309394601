import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BuildingsService, FloorsService } from '@app/core/api/SWAGGER-SPACES-DEFINITIONS/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpaceLogicService } from '@core/services/space-logic/space-logic.service';
import { Building } from '@shared/models/space/space.interface';
import { SpacePaginationService } from '@app/core/services/spaces-pagination/spaces-pagination.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  filterForm: FormGroup;
  filterObject: any;
  building: string;
  type: string;
  floorId: any;
  floor: any;
  state: any;
  cards: any;
  access: any;
  @Input() isLockList = false;
  @Output() search = new EventEmitter();
  @Output() getId = new EventEmitter();
  @Output() paginationReset = new EventEmitter();
  buildings: any;
  floors: any;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private floorService: FloorsService,
              private buildingService: BuildingsService,
              private spaceLogicService: SpaceLogicService,
              private spacePaginationService: SpacePaginationService) {
  }

  ngOnInit() {
    this.filterForm = new FormGroup({
      building: new FormControl(null, Validators.required),
      floor: new FormControl(null, Validators.required)
    });
    this.buildingService.spacesBuildingGet()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: Building[]) => {
        if (res) {
          this.buildings = res;
          this.spaceLogicService.buildings = res;
        }
      });
  }

  selectBuilding(building) {
    this.filterForm.controls.floor.reset();
    const id = building.id;
    this.building = id;
    this.spaceLogicService.getFloorsByBuildingId({ Id: id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.floors = res;
          this.spaceLogicService.buildingSelectedForSearch = building;
        }
      });
  }

  selectFloor(event) {
    this.floorId = event.id;
  }

  formatObject() {
    this.filterForm.addControl('building', new FormControl());
    this.filterForm.addControl('floor', new FormControl());
    this.filterObject = {
      building: this.filterForm.value.building,
      floor: this.filterForm.value.floor
    };
  }

  searchSpaces() {
    if (this.floorId) {
      const pagination = {
        page: 0,
        size: 20
      };
      const id = this.floorId;

      if (!this.isLockList) {
        this.spaceLogicService.getSpacesByFloorId({ Id: id, pagination })
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.cards = res.content;
            this.spacePaginationService.setSpacesList(res);
            this.spacePaginationService.initPages();
            this.paginationReset.emit();
          });
      } else {
        this.spaceLogicService.getSpacesLocked(this.building, id, pagination)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.spacePaginationService.setLockParkingList(res);
            this.spacePaginationService.initPages();
            this.paginationReset.emit();
          });
      }

      this.formatObject();
      this.search.emit(this.filterObject);
      this.getId.emit(this.floorId);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
