<div class="item-card-form">
  <form class="row" [formGroup]="spaceForm">
    <div class="col-md-4 col-sm-6">
      <div class="form-group">
        <label class="mandatory-field" for="formGroupExampleInput"
        >{{ "SPACE.TYPE" | translate}}</label
        >
        <ng-select
          (change)="changeType($event)"
          [items]="typespace"
          [searchable]="false"
          class="customSelect"
          formControlName="typespace"
        >
        </ng-select>
      </div>
    </div>
    <div class="col-md-4 col-sm-6">
      <div class="form-group">
        <label class="mandatory-field" for="formGroupExampleInput"
        >{{ "SPACE.BUILDING" | translate}}</label
        >
        <ng-select
          (change)="selectBuilding($event)"
          [items]="buildings"
          [markFirst]="true"
          [searchable]="false"
          bindLabel="name"
          class="customSelect"
          formControlName="building">
        </ng-select>
      </div>
    </div>
    <div class="col-md-2 col-sm-6">
      <div class="form-group">
        <label class="mandatory-field" for="formGroupExampleInput"
        >{{ "SPACE.FLOOR" | translate}}</label
        >
        <ng-select
          [items]="floors"
          [markFirst]="true"
          [searchable]="false"
          bindLabel="name"
          class="customSelect"
          formControlName="floor"
          notFoundText="{{ 'SPACE.CHOOSE_BUILDING' | translate }}">
        </ng-select>
      </div>
    </div>
    <div class="col-md-2 col-sm-6">
      <div class="form-group">
        <label class="mandatory-field" for="costInput"
        >{{ "SPACE.COST" | translate}}</label
        >
        <input
          type="number"
          class="form-control"
          id="costInput"
          formControlName="cost"
        />
      </div>
    </div>
    <div class="col-md-4 col-sm-6">
      <div class="form-group">
        <label class="mandatory-field" for="descriptionInput"
        >{{ "SPACE.DESCRIPTION" | translate}}</label
        >
        <input
          type="text"
          class="form-control"
          id="descriptionInput"
          formControlName="description"
        />
      </div>
    </div>
    <div class="col-md-4 col-sm-6">
      <div class="form-group">
        <label class="mandatory-field" for="formGroupExampleInput"
        >{{ "SPACE.STATUS" | translate}}</label
        >
        <ng-select
          [items]="status"
          [markFirst]="true"
          [searchable]="false"
          class="customSelect"
          formControlName="status">
        </ng-select>
      </div>
    </div>
    <div class="col-md-4 col-sm-6">
      <div class="form-group">
        <label class="mandatory-field" for="formGroupExampleInput"
        >{{ "SPACE.ACCESIBILITY" | translate}}</label
        >
        <ng-select
          [items]="selectValue"
          [markFirst]="true"
          [searchable]="false"
          (ngModelChange)="checkInputsCompatibility($event, InputType.Accessibility)"
          class="customSelect"
          formControlName="accessibility"
        >
        </ng-select>
      </div>
    </div>
    <div class="col-md-4 col-sm-6">
      <div class="form-group">
        <label class="mandatory-field" for="sharedSpaceInput">{{ "SPACE.SHARED" | translate}}</label>
        <ng-select
          id="sharedSpaceInput"
          [items]="selectValue"
          (ngModelChange)="checkInputsCompatibility($event, InputType.Shared)"
          [markFirst]="true"
          [searchable]="false"
          class="customSelect"
          formControlName="sharedSpace"
        >
        </ng-select>
      </div>
    </div>
    <div class="col-md-4 col-sm-6">
      <div class="form-group">
        <label class="mandatory-field" for="electricSpaceInput">{{ "SPACE.ELECTRIC" | translate}}</label>
        <ng-select
          id="electricSpaceInput"
          (ngModelChange)="checkInputsCompatibility($event, InputType.Electric)"
          [items]="selectValue"
          [markFirst]="true"
          [searchable]="false"
          class="customSelect"
          formControlName="electricSpace">
        </ng-select>
      </div>
    </div>

    <!-- EMPLOYEE SEARCH INPUT FIELD -->
    <div class="col-md-6 col-sm-6">
      <label [translate]="'SPACE.EMPLOYEE'"></label>
      <!-- INPUT -->
      <div class="input-container">
        <app-filter-from-url
          [keyToShow]="'name'"
          [dataByDefault]="employeeToModify"
          [placeholder]="'SPACE.EMPLOYEE'"
          [errorMessage]="'SPACE.FORM_LENGTH'"
          (selectedItem)="selectedEmployee($event)"
        >
        </app-filter-from-url>
      </div>
    </div>
  </form>
  <div class="btn-block border-top justify-content-center">
    <button (click)="closeDetail()" *ngIf="showCardDetail" class="btn btn-secondary"
            type="button">{{"BUTTON.CLOSE" | translate}}</button>
    <button (click)="showCardDetail ? modifySpace() : createSpace()"
            [disabled]="(!spaceForm.valid || spaceForm.pristine) && employeeId === undefined" class="btn btn-primary"
            type="button"> {{ (showCardDetail ? "BUTTON.ACCEPT" : "BUTTON.CREATE") | translate }} </button>
  </div>
</div>
