import { timer, throwError, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/**
 * Exported function type
 */
// tslint:disable-next-line:variable-name
export type mustRetryFn = ({ status: number }) => boolean;

/**
 * Exported interface to interact with the retry parameters in a typed way
 */
export interface RetryParams {
  enable?: boolean;
  maxAttempts?: number;
  interval?: number;
  mustRetry?: mustRetryFn;
  errorCodes?: Array<number>;
}

/**
 * Default retry parameters
 */
const defaultParams: RetryParams = {
  enable: true,
  maxAttempts: 3,
  interval: 2000,
  mustRetry: ({ status }) => status >= 400
};
/**
 * Retry strategy
 * @param params: retry parameters to be considered on the strategy
 */
export const restRetryStrategy = (params: RetryParams = {}) => (attempts: Observable<any>) => attempts.pipe(
  mergeMap((error, i) => {
    const { enable, maxAttempts, interval, mustRetry } = { ...defaultParams, ...params };
    const retryAttempt = i + 1;
    // If max number of retries have been met or response has status code we don't want to retry, throw error
    // The error thrown will be handled by the custom error handler
    if (!enable || retryAttempt > maxAttempts || !mustRetry(error)) {
      return throwError(error);
    }
    return timer(interval);
  })
);
