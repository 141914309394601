<div class="space-allocation-container">
  <h4 [translate]="'LOGIN.USER'"></h4>
  <div class="item-card box p-3">
    <div class="item-card-data">
      <div class="row">
        <div class="col-md-1 col-sm-6">
          <div class="rounded-circle">
            <img alt="" class="img-fluid" src="assets/img/icon-user.png"/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="label-txt">
            {{ "SPACE_ALLOCATION.USER_ID" | translate }}
          </div>
          <div class="breakword">
            {{card.employee.employeeId}}
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="label-txt">
            {{ "SPACE_ALLOCATION.NAME" | translate }}
          </div>
          <div>
            {{card.employee.name}}
          </div>
        </div>
        <div class="col-md-2 col-sm-6">
          <div class="label-txt">
            {{ "SPACE_ALLOCATION.OCCUPANCY_DATE" | translate }}
          </div>
          <div>
            {{card.day | date: 'dd/MM/yyyy' }}
          </div>
        </div>

        <div class="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
          <div class="icon-width-control">
            <i class="icon-accessibility-on" *ngIf="card.disabled"></i>
          </div>
          <div class="icon-width-control">
            <i *ngIf="card.type === 'PARKING_PLACE'" class="icon-car"></i>
          </div>
          <i class="icon-monitor"></i>
        </div>
      </div>
    </div>
  </div>
  <h4 [translate]="'KEY.WORK_PLACE'"></h4>
  <div class="space-allocation-item">
    <form class="row" [formGroup]="filterSpaceForm">
      <div class="col-md-4 col-sm-6">
        <div class="form-group">
          <label class="mandatory-field" for="formGroupExampleInput"
          >{{ "SPACE.BUILDING" | translate}}</label
          >
          <ng-select
            (change)="selectSpaceBuilding($event)"
            [markFirst]="true"
            [searchable]="false"
            class="customSelect"
            formControlName="buildingSpace"
            placeholder=""
          >
            <ng-option *ngFor="let building of buildings" [value]="building">
              {{building.name}}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="mandatory-field" for="formGroupExampleInput"
          >{{ "SPACE.FLOOR" | translate}}</label
          >
          <ng-select
            (change)="selectSpaceFloor($event)"
            [markFirst]="true"
            [searchable]="false"
            class="customSelect"
            formControlName="floorSpace"
            placeholder=""
          >
            <ng-option *ngFor="let floor of floors" [value]="floor">
              {{floor.name}}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </form>

    <!-- TABLE -->
      <ng-container class="space-allocation-table">
        <ion-content class="assign-list-controller ">
          <ion-list>
              <table *ngIf="spaceList" class="table table-striped">
                  <thead>
                  <tr>
                    <th [translate]="'SPACE_ALLOCATION.BUILDING'"></th>
                    <th [translate]="'SPACE_ALLOCATION.FLOOR'"></th>
                    <th [translate]="'SPACE_ALLOCATION.DESCRIPTION'"></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let space of spaceList">
                    <td> {{buildingSpace.name}} </td>
                    <td> {{floorSpace.name}} </td>
                    <td>  {{ space.name }} </td>
                    <!-- CHECK -->
                    <td>
                      <div class="">
                        <label class="check-container">
                          <input (change)="onItemChange()" [(ngModel)]="radioSelected"
                                 name="checkedSpace" type="radio" value="{{space.id}}">
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
          </ion-list>
          <app-no-results-msg *ngIf="showMsgSpace && !spaceList.length"
                        imgPath="assets/img/no-results.png"></app-no-results-msg>
              <ion-infinite-scroll (ionInfinite)="onScrollSpaces($event)">
                  <ion-infinite-scroll-content
                    loadingSpinner="bubbles">
                  </ion-infinite-scroll-content>
                </ion-infinite-scroll>
        </ion-content>
      </ng-container>
    <h4 class="mt-5" *ngIf="card.type === 'PARKING_PLACE' && radioSelected" [translate]="'KEY.PARKING_PLACE'"></h4>
    <form class="row" [formGroup]="filterParkingForm" *ngIf="card.type === 'PARKING_PLACE' && radioSelected">
      <div class="col-md-4 col-sm-6">
        <div class="form-group">
          <label class="mandatory-field" for="formGroupExampleInput"
          >{{ "SPACE.BUILDING" | translate}}</label
          >
          <ng-select
            (change)="selectParkingBuilding($event)"
            [markFirst]="true"
            [searchable]="false"
            class="customSelect"
            formControlName="buildingParking"
            placeholder=""
          >
            <ng-option *ngFor="let building of buildings" [value]="building">
              {{building.name}}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="mandatory-field" for="formGroupExampleInput"
          >{{ "SPACE.FLOOR" | translate}}</label
          >
          <ng-select
            (change)="selectParkingFloor($event)"
            [markFirst]="true"
            [searchable]="false"
            class="customSelect"
            formControlName="floorParking"
            placeholder=""
          >
            <ng-option *ngFor="let floor of floors" [value]="floor">
              {{floor.name}}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </form>
    
    <div *ngIf="card.type === 'PARKING_PLACE' && radioSelected">
      <ion-content class="assign-list-controller ">
        <ion-list>
        <table *ngIf="parkingList" class="table table-striped">
          <thead *ngIf="parkingList">
          <tr>
            <th [translate]="'SPACE_ALLOCATION.BUILDING'"></th>
            <th [translate]="'SPACE_ALLOCATION.FLOOR'"></th>
            <th [translate]="'SPACE_ALLOCATION.DESCRIPTION'"></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let parking of parkingList">
            <td> {{ buildingPark.name }} </td>
            <td> {{ floorPark.name }} </td>
            <td>  {{ parking.name }} </td>
            <!-- CHECK -->
            <td>
              <div class="">
                <label class="check-container">
                  <input (change)="onParkItemChange()" (click)="compareParking(parking)"
                         [(ngModel)]="radioParkSelected" name="checkedParking" type="radio"
                         value="{{parking.id}}">
                  <span class="checkmark"></span>
                </label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </ion-list>
      <app-no-results-msg *ngIf="showMsgPark && !parkingList.length"
                        imgPath="assets/img/no-results.png"></app-no-results-msg>
      <ion-infinite-scroll (ionInfinite)="onScrollParking($event)">
          <ion-infinite-scroll-content
            loadingSpinner="bubbles">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-content>
      </div>
    <!-- BUTTON BLOCK: ASSIGN -->
    <div class="btn-block border-top justify-content-center mt-5">
      <button (click)="assignFreePlace()" [disabled]="!radioSelected" class="btn btn-primary" type="submit">
        {{ "BUTTON.ASSIGN" | translate}}
      </button>
    </div>
  </div>

</div>
