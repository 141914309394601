import { Directive } from '@angular/core';

@Directive({
  selector: '[esdaDemo]'
})
export class DemoDirective {

  constructor() { }

}
