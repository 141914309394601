import { AuthService } from '@core/services/auth/auth.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StateHandlerService } from '@app/core/services/state-handler/state-handler.service';
import { StorageService } from '@core/services/storage/storage.service';
import { HomeService } from '@core/api/SWAGGER-SPACES-DEFINITIONS/services/home.service';
import { filter, map, take } from 'rxjs/operators';
import { Employee } from '@core/api/SWAGGER-SPACES-DEFINITIONS/models/employee';
import { UtilsService } from '@core/services/utils/utils.service';
import { NavigationEnd, Router } from '@angular/router';
import { AppUrls } from '@config/app-urls.config';

interface Header {
  title: string;
  nameSurvey?: string;
  dateOpen?: string;
  dateClose?: string;
  headerDesktopVisible?: boolean;
  headerDesktopUserName?: string;
}

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  isHeaderVisible: boolean = false;
  private infoHeader$: BehaviorSubject<Header> = new BehaviorSubject<Header>({ title: '' });

  constructor(private stateHandlerService: StateHandlerService,
              private storageService: StorageService,
              private homeService: HomeService,
              private router: Router,
              private authService: AuthService) {
    this.setDefaultHeaderVisibility();
  }

  /**
   * Get actual title as observable
   */
  getInfoHeaderObservable(): Observable<Header> {
    return this.infoHeader$.asObservable();
  }

  /**
   * Set the new variable for title
   */
  setInfoHeader(newInfo: Header): void {
    this.infoHeader$.next(newInfo);
  }

  /**
   * Get actual logged user
   */
  getLoggedUser() {
    this.homeService.getEmployeeV2()
      .pipe(
        take(1),
        map(res => res.employee)
      )
      .subscribe(async (employee: Employee) => {
        if (employee.rol !== 'Admin'){
          await this.storageService.remove('loggedUser');
          await this.authService.oAuthLogout();
          await this.router.navigate([AppUrls.AppLogin], { replaceUrl: true });
        }
        else {
          this.setLoggedUser(UtilsService.uppercaseWords(employee.name));
        }
      });
  }

  private setLoggedUser(loggedUser) {
    let infoHeader = this.infoHeader$.getValue();

    infoHeader = {
      ...infoHeader,
      headerDesktopUserName: loggedUser
    };

    this.setInfoHeader(infoHeader);
  }

  private setDefaultHeaderVisibility(): void {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe((navEnd: NavigationEnd) => {
        if (navEnd.url !== `/${ AppUrls.AppLogin }`) {
          this.isHeaderVisible = true;
        }
      });
  }

}
