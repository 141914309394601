import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfigService } from './services/config/config.service';
import { HttpCustomInterceptor } from './interceptors/http-custom.interceptor';
import { HttpErrorsInterceptor } from './interceptors/http-errors.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { RetryInterceptor } from './interceptors/retry.interceptor';
import { CoreRoutingModule } from './core-routing.module';
import { ShellModule } from '@shell/shell.module';
import { GenericModalModule } from '@shared/modules/generic-modal/generic-modal.module';
import { AuthService } from './services/auth/auth.service';
import { HttpBlobErrorInterceptor } from '@core/interceptors/http-blob-errors-interceptor.service';

export const HttpLoaderFactory = (handler: HttpBackend) => {
  const http = new HttpClient(handler);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export const ConfigLoadFactory = (config: ConfigService) => async () => await config.getConfig();

@NgModule({
  imports: [
    CommonModule,
    CoreRoutingModule,
    HttpClientModule,
    ShellModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    GenericModalModule
  ],
  providers: [/* Keep empty. Providers will be exported with the 'forRoot' method so as to be imported only on app module */]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      // Avoid loading this module multiple times
      throw new Error('Do not try to load CoreModule multiple times, please. Import it in AppModule only :)');
    }
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [/* We'll use the .forRoot() on AppModule, so as to make core providers available app-wide */
        AuthService,
        ConfigService,
        {
          provide: APP_INITIALIZER,
          useFactory: ConfigLoadFactory,
          deps: [ConfigService],
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorsInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpBlobErrorInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpCustomInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RetryInterceptor,
          multi: true
        }
        /*
        {
          provide: HTTP_INTERCEPTORS,
          useClass: InterceptorX, // We can add multiple interceptors (they'll be considered in order)
          multi: true
        } */
      ]
    };
  }
}
