import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomLoggerService } from '@core/services/logger/logger.service';

const bodyMethods = {
  post: 1,
  put: 1,
  patch: 1
};

/**
 * Custom wrapper for angular http client. It accepts custom types
 */
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private readonly http: HttpClient,
    private readonly logger: CustomLoggerService
    ) { }

  /**
   * It returns an observable
   * @param method (get, post, put, patch, delete, options). It will determine if the method requires a body payload
   * @param url for desired endpoint
   * @param body (optional)
   * @param options (optional) for custom request parameters (see angular http client documentation)
   */
  request<T>(method: string, url: string, body?: object, options?: object): Observable<T> {
    this.logger.log(`Logged request at ${new Date().toISOString()}:
    \n Url: ${url}
    \n Method: ${method}
    \n Body: ${body ? body : 'no body sent'}
    \n Options: ${options ? options : 'no options'}`);
    method = method.toLowerCase();
    return bodyMethods[method] ? this.http[method]<T>(url, body, options) : this.http[method]<T>(url, options);
  }
}
