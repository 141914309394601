import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { WizardStep } from '@shared/constants/survey/enums';
import { SurveyService } from '@core/services/survey/survey.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslationsService } from '@core/services/translations/translations.service';

@Component({
  selector: 'app-step-bar-nav',
  templateUrl: './step-bar-nav.component.html',
  styleUrls: ['./step-bar-nav.component.scss']
})
export class StepBarNavComponent implements OnInit, OnDestroy {
  stepsList = [
    {
      id: 1,
      name: 'SURVEY.CREATION_TAB_STEP1',
      isActive: true,
      isDone: false
    },
    {
      id: 2,
      name: 'SURVEY.CREATION_TAB_STEP2',
      isActive: false,
      isDone: false
    }
  ];
  @Output() changeStep: EventEmitter<any> = new EventEmitter();
  private destroyed$: Subject<void> = new Subject<void>();

  constructor(public surveyService: SurveyService,
              private translationsService: TranslationsService) {
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.translateTabsTitle();
    this.subscribeToChangeStep();
  }

  selectStep(step) {
    if (step.id === 1) {
      this.surveyService.changeStep(WizardStep.SurveyInfo);
      this.changeStep.emit(WizardStep.SurveyInfo);
    }
  }

  private subscribeToChangeStep(): void {
    this.surveyService.wizardStep
      .pipe(takeUntil(this.destroyed$))
      .subscribe(step => {
        switch (step) {
          case WizardStep.SurveyInfo:
            this.stepsList[step].isActive = true;
            this.stepsList[step].isDone = false;
            this.stepsList[WizardStep.SurveyStructure].isActive = false;
            this.stepsList[WizardStep.SurveyStructure].isDone = false;
            break;
          case WizardStep.SurveyStructure:
            this.stepsList[WizardStep.SurveyInfo].isActive = false;
            this.stepsList[WizardStep.SurveyInfo].isDone = true;
            this.stepsList[step].isActive = true;
            this.stepsList[step].isDone = false;
            break;
          default:
            break;
        }
      });
  }

  private translateTabsTitle(): void {
    this.stepsList.map(tab => {
      tab.name = this.translationsService.instant(tab.name);
    });
  }
}
