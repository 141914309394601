import { Injectable } from '@angular/core';
import { TranslationsService } from '@core/services/translations/translations.service';
import { AuthService } from '@core/services/auth/auth.service';

import { Platform } from '@ionic/angular';
import { Plugins, registerWebPlugin, StatusBarStyle } from '@capacitor/core';

const { SplashScreen, StatusBar } = Plugins;

/**
 * This service will handle the required initializations and subscriptions required on app start (translations and security mainly).
 * By importing it on app.component and invoking it from there we avoid bloating that component, since
 * it should remain as empty as possible
 */
@Injectable({
  providedIn: 'root'
})
export class InitService {

  constructor(
    private readonly auth: AuthService,
    private readonly platform: Platform,
    private readonly translate: TranslationsService
  ) { }

  public init() {
    return new Promise(async resolve => {
      this.translate.init();
      await this.registerCapPlugins();
      await this.auth.init();
      this.platform.ready().then(() => {
        if (!this.platform.is('desktop')) {
          StatusBar.setStyle(
            {
              style: StatusBarStyle.Light
            }
          );
          SplashScreen.hide();
        }
      });
      resolve(true);
    });
  }

  /**
   * Helper function for Capacitor plugins register
   *
   * Ideally all Capacitor plugins should be registered here, but some of them
   * may require independent registration on their own services (such as OAuth2)
   * in order to work properly
   *
   */
  public registerCapPlugins() {
    return new Promise(resolve => {
      // registerWebPlugin(CapacitorPlugin);
      resolve(true);
    });
  }
}
