import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/components/loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header/components/header.component';
import { FooterComponent } from './footer/components/footer.component';

// Ionic
import { IonicArqModule } from '@app/ionic.module';
import { SharedModule } from '@app/shared/shared.module';

/**
 * This module will contain the main layout elements
 * for the app: header, footer and loader
 *
 * It will also handle the shell components for better ui experience
 */
@NgModule({
  declarations: [/* Components, directives and pipes to be shared */
    HeaderComponent,
    LoaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    IonicArqModule,
    SharedModule,
    TranslateModule
  ],
  exports: [/* Components, directives and pipes to be shared */
    HeaderComponent,
    LoaderComponent,
    FooterComponent
  ]
})
export class ShellModule {}
