<div class="modal-container">
  <div *ngIf="firstTitle" class="modal-title d-flex justify-content-between">
    <div>
      {{ firstTitle | translate }}
    </div>
    <i (click)="dismissModal(secondButton)" class="icon-close"></i>
  </div>
  <div class="modal-main d-flex flex-column align-items-center">
    <i *ngIf="iconToShow" [ngClass]="iconToShow"></i>
    <div *ngIf="text" [translate]="text" class="modal-text">
    </div>
    <div *ngIf="!text && textWithParams" [innerHTML]="textWithParams[0].txtVar | translate: textArgs[0]"
         class="modal-text p-4">
    </div>
    <div *ngIf="selectData" class="select-date-container col-md-8">
      <form *ngIf="dateForm" [formGroup]="dateForm" class="row">
        <div class="col-md-6 col-sm-6">
          <label [translate]="'SURVEY.START_DATE'" for="starDateDatepicker"></label>
          <div class="input-group date-input">
            <input
              #sd="ngbDatepicker" (click)="sd.toggle()"
              [readonly]="true" class="form-control" formControlName="startDate"
              id="starDateDatepicker" name="sd" ngbDatepicker placeholder="dd/mm/aaaa">
            <i (click)="sd.toggle()" class="icon-calendar"></i>
          </div>
          <div *ngIf="startDate.invalid && (startDate.dirty || startDate.touched)"
               class="alert alert-danger">

            <span *ngIf="startDate.errors.required || startDate.touched" [translate]="'SURVEY.FORM.REQUIRED'">
            </span>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <label [translate]="'SURVEY.ENDING_DATE'" for="endDateDatepicker"></label>
          <div class="input-group date-input">
            <input #ed="ngbDatepicker" (click)="ed.toggle()" [readonly]="true"
                   class="form-control" formControlName="endDate" id="endDateDatepicker" name="ed"
                   ngbDatepicker placeholder="dd/mm/aaaa">
            <i (click)="ed.toggle()" class="icon-calendar"></i>
          </div>
          <div *ngIf="endDate.invalid && (endDate.dirty || endDate.touched)"
               class="alert alert-danger">

            <span *ngIf="endDate.errors.required || endDate.touched" [translate]="'SURVEY.FORM.REQUIRED'">
            </span>
          </div>
        </div>
        <div *ngIf="dateForm.errors?.dates ||  dateForm.errors?.datesInterval || dateForm.errors?.datesLater || dateForm.errors?.datesLocked"
             class="alert alert-danger ion-margin-top form-error-container">
          <div class="left-container">
            <i class="icon-alert"></i>
          </div>
          <div class="ion-padding-start">
            <div *ngIf="dateForm.errors?.dates" [translate]="'SURVEY.FORM.DATES_ERROR'"></div>
            <div *ngIf="dateForm.errors?.datesInterval" [innerHTML]="'FORM.DATES_15D_INTERVAL_ERROR' | translate "></div>
            <div *ngIf="dateForm.errors?.datesLater" [innerHTML]="'FORM.DATES_15D_LATER_ERROR' | translate "></div>
            <div *ngIf="dateForm.errors?.datesLocked" [innerHTML]="'SPACES_LOCKED.ERROR_LOCKDAYS' | translate "></div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- CHECK DÍAS DE LA SEMANA PARA BLOQUEO / Para la modal "Bloqueo de plazas"-->
  <div class="d-flex justify-content-center mb-5" *ngIf="hasWeekDays" >
    <div class="flex-column">
      <div class="label-check-group">
        <label class="mandatory-field" for="formGroupExampleInput">{{ 'MODAL.WEEK_DAYS' | translate }}</label>
      </div>
      <div class="d-flex justify-content-start">

        <div *ngFor="let day of weekDays; let i=index" class="d-flex justify-space-around align-items-center mr-3">
          <ng-container class="form-group">
            <div class="">
              <div class="">
                <label class="regulation-check">
                  <input type="checkbox" [value]="day.value" (click)="checkDay(day.value)" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="label-text">{{ day.description }}</div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-main d-flex flex-column align-items-center">
    <div class="modal-btn-block">
      <button (click)="dismissModal(secondButton.action)"
              *ngIf="secondButton"
              class="btn btn-secondary" type="button">
        {{ secondButton.text | translate }}
      </button>
      <button (click)="dismissModal(firstButton.action)"
              *ngIf="firstButton && !selectData"
              class="btn btn-primary" type="button">
        {{ firstButton.text | translate }}
      </button>
      <button (click)="dismissModal(firstButton.action)"
              *ngIf="firstButton && selectData"
              [disabled]="!dateForm.valid || (hasWeekDays && !this.weekDaySelected.length)"
              class="btn btn-primary" type="button">
        {{ firstButton.text | translate }}
      </button>
    </div>
  </div>  

</div>

