/* tslint:disable */
import { Injectable } from '@angular/core';
import { BeDigitalSurveysApiBaseService } from '../be-digital-surveys-api-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { BeDigitalSurveysApiRequestBuilder } from '../be-digital-surveys-api-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Survey } from '../models/survey';
import { SurveyEdit } from '../models/survey-edit';
import { SurveysPage } from '../models/surveys-page';
import { SurveysSearchParams } from '@core/api/BE-DIGITAL-SURVEYS-API/models/survey-search-params';

@Injectable({
  providedIn: 'root'
})
export class SurveysApiService extends BeDigitalSurveysApiBaseService {
  /**
   * Path part for operation findSurveys
   */
  static readonly FindSurveysPath = '/surveys';
  /**
   * Path part for operation createSurvey
   */
  static readonly CreateSurveyPath = '/surveys';
  /**
   * Path part for operation getSurveyById
   */
  static readonly GetSurveyByIdPath = '/surveys/{id}';
  /**
   * Path part for operation modifySurveyById
   */
  static readonly ModifySurveyByIdPath = '/surveys/{id}';

  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Find surveys.
   *
   * Find surveys using multiple parameters.
   *
   * This method provides customization options.
   * To access only the response body, use `findSurveys()` instead.
   *
   * This method doesn't expect any request body.
   */
  findSurveys$Response(params?: SurveysSearchParams): Observable<SurveysPage> {

    const req = new BeDigitalSurveysApiRequestBuilder(SurveysApiService.FindSurveysPath, 'get');
    if (params) {
      req.query('active', params.active, {});
      req.query('opensOn', params.opensOn, {});
      req.query('closesOn', params.closesOn, {});
      req.query('title', params.title, {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as SurveysPage;
      })
    );
  }

  /**
   * Find surveys.
   *
   * Find surveys using multiple parameters.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `findSurveys$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  findSurveys(params?: SurveysSearchParams): Observable<SurveysPage> {

    return this.findSurveys$Response(params).pipe(
      map(r => r as SurveysPage)
    );
  }

  /**
   * Create survey.
   *
   * Create survey.
   *
   * This method provides customization options.
   * To access only the response body, use `createSurvey()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSurvey$Response(params: {

    /**
     * Survey.
     */
    body: Survey
  }): Observable<Survey> {

    const req = new BeDigitalSurveysApiRequestBuilder(SurveysApiService.CreateSurveyPath, 'post');
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Survey;
      })
    );
  }

  /**
   * Create survey.
   *
   * Create survey.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `createSurvey$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSurvey(params: {

    /**
     * Survey.
     */
    body: Survey
  }): Observable<Survey> {

    return this.createSurvey$Response(params).pipe(
      map(r => r as Survey)
    );
  }

  /**
   * Get survey by id.
   *
   * Get survey by id.
   *
   * This method provides customization options.
   * To access only the response body, use `getSurveyById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSurveyById$Response(params: {

    /**
     * Survey id.
     */
    id: number;

  }): Observable<Survey> {

    const req = new BeDigitalSurveysApiRequestBuilder(SurveysApiService.GetSurveyByIdPath, 'get');
    if (params) {
      req.path('id', params.id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Survey;
      })
    );
  }

  /**
   * Get survey by id.
   *
   * Get survey by id.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getSurveyById$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getSurveyById(params: {

    /**
     * Survey id.
     */
    id: number;

  }): Observable<Survey> {

    return this.getSurveyById$Response(params).pipe(
      map(r => r as Survey)
    );
  }

  /**
   * Modify survey by id.
   *
   * modify survey by id.
   *
   * This method provides customization options.
   * To access only the response body, use `modifySurveyById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifySurveyById$Response(params: {

    /**
     * Survey id.
     */
    id: number;

    /**
     * Survey.
     */
    body: SurveyEdit
  }): Observable<void> {

    const req = new BeDigitalSurveysApiRequestBuilder(SurveysApiService.ModifySurveyByIdPath, 'patch');
    if (params) {
      req.path('id', params.id, {});
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Modify survey by id.
   *
   * modify survey by id.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `modifySurveyById$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifySurveyById(params: {

    /**
     * Survey id.
     */
    id: number;

    /**
     * Survey.
     */
    body: SurveyEdit
  }): Observable<void> {

    return this.modifySurveyById$Response(params).pipe(
      map(r => r as void)
    );
  }

  /**
   * Path part for operation getSurveySummary
   */
  static readonly GetSurveySummaryPath = '/surveys/{surveyId}/report';
  /**
   * Path part for operation getSurveyInputAnswers
   */
  static readonly GetSurveyInputAnswersPath = '/surveys/{id}/input-answers-report';

  /**
   * Get survey summary.
   *
   * Get survey summary.
   *
   * This method provides customization options.
   * To access only the response body, use `getSurveySummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSurveySummary$Response(params: {

    /**
     * Survey id.
     */
    surveyId: number;

  }): Observable<Blob> {

    const req = new BeDigitalSurveysApiRequestBuilder(SurveysApiService.GetSurveySummaryPath, 'get');
    if (params) {
      req.path('surveyId', params.surveyId, {});
    }

    const sendReq = req.build({
      responseType: 'blob',
      accept: 'application/vnd.ms-excel'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Blob;
      })
    );
  }

  /**
   * Get survey summary.
   *
   * Get survey summary.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getSurveySummary$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getSurveySummary(params: {

    /**
     * Survey id.
     */
    surveyId: number;

  }): Observable<Blob> {

    return this.getSurveySummary$Response(params).pipe(
      map(r => r as Blob)
    );
  }

  /**
   * Get input answers.
   *
   * Get input answers.
   *
   * This method provides customization options.
   * To access only the response body, use `getSurveyInputAnswers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSurveyInputAnswers$Response(params: {

    /**
     * Survey id.
     */
    id: number;

    /**
     * Date from query.
     */
    dateFrom: string;

    /**
     * Date to query.
     */
    dateTo: string;

  }): Observable<Blob> {

    const req = new BeDigitalSurveysApiRequestBuilder(SurveysApiService.GetSurveyInputAnswersPath, 'get');
    if (params) {
      req.path('id', params.id, {});
      req.query('dateFrom', params.dateFrom, {});
      req.query('dateTo', params.dateTo, {});
    }

    const sendReq = req.build({
      responseType: 'blob',
      accept: 'application/vnd.ms-excel'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Blob;
      })
    );
  }

  /**
   * Get input answers.
   *
   * Get input answers.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getSurveyInputAnswers$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getSurveyInputAnswers(params: {

    /**
     * Survey id.
     */
    id: number;

    /**
     * Date from query.
     */
    dateFrom: string;

    /**
     * Date to query.
     */
    dateTo: string;

  }): Observable<Blob> {

    return this.getSurveyInputAnswers$Response(params).pipe(
      map(r => r as Blob)
    );
  }
}
