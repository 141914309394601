<!-- STEP BAR NAVIGATOR -->
<nav class="step-bar-container">
    <div class="step-items-container">
        <!-- CLASS.selected FOR CURRENT STEP AND .past FOR PAST STEP -->
        <div
                *ngFor="let step of stepsList"
                [ngClass]="{ 'selected': step.isActive, 'is-done': step.isDone }"
                class="step-item"
                (click)="selectStep(step)">
            <div class="step-icon-circle">
                <div class="step-id">
                    {{ step.id }}
                </div>
            </div>
            <div class="step-text">
                <div>
                    {{ step.name | translate }}
                </div>
            </div>
        </div>
    </div>
</nav>
