import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BuildingsService,
  EmployeesService,
  FloorsService,
  RequestsService,
  SpacesService
} from '@app/core/api/SWAGGER-SPACES-DEFINITIONS/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Reservation, SpaceType } from '@app/core/api/SWAGGER-SPACES-DEFINITIONS/models';
import { SpacePaginationService } from '@app/core/services/spaces-pagination/spaces-pagination.service';
import { IonInfiniteScroll } from '@ionic/angular';

@Component({
  selector: 'app-space-user-assign',
  templateUrl: './space-user-assign.component.html',
  styleUrls: ['./space-user-assign.component.scss'],
})
export class SpaceUserAssignComponent implements OnInit, OnDestroy {
  @ViewChild(IonInfiniteScroll, {static: false}) infiniteScroll: IonInfiniteScroll;
  filterSpaceForm: FormGroup;
  checkForm: FormGroup;
  filterParkingForm: FormGroup;
  floors: any;
  parkFloors: any;
  floorId: any;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() card;
  @Input() buildings;
  @Output() showAssign: EventEmitter<any> = new EventEmitter();
  spaceList: any;
  parkingList: any;
  radioSel: any;
  radioParkSel: any;
  radioSelected: any;
  eventParking: any;
  radioParkSelected: any;
  floorSpace: any;
  floorPark: any;
  buildingSpace: any;
  buildingPark: any;
  showMsgPark: boolean = false;
  showMsgSpace: boolean = false;
  constructor(
    private employeeService: EmployeesService,
    private floorService: FloorsService,
    private buildingService: BuildingsService,
    private spacesService: SpacesService,
    private requestService: RequestsService,
    private spacePaginationService: SpacePaginationService) { }

  ngOnInit() {
    this.filterSpaceForm = new FormGroup({
      buildingSpace: new FormControl(null, Validators.required),
      floorSpace: new FormControl(null, Validators.required)
    });
    this.filterParkingForm = new FormGroup({
      buildingParking: new FormControl(null),
      floorParking: new FormControl(null),
    });
  }

  selectSpaceBuilding(event) {
    const id = event.id;
    this.filterSpaceForm.controls.floorSpace.reset();
    this.spaceList = [];
    this.buildingSpace = event;
    this.buildingService.spacesBuildingIdFloorsGet({ Id: id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.floors = res;
        }
      });
  }
  selectParkingBuilding(event) {
    const id = event.id;
    this.filterParkingForm.controls.floorParking.reset();
    this.parkingList = [];
    this.buildingPark = event;
    this.buildingService.spacesBuildingIdFloorsGet({ Id: id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.parkFloors = res;
        }
      });
  }
  selectSpaceFloor(event) {
    this.floorId = event.id;
    this.floorSpace = event;
    const requestId = this.card.id;
    const pagination = {
      page: 0,
      size: 20
    };
    this.spacesService.getRequestIdFreeSpaceByFloor({
      requestId,
      spaceType: SpaceType.WorkPlace,
      floorId: this.floorId,
      pagination})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.parkingList = [];
          this.radioSelected = undefined;
          this.parkFloors = undefined;
          this.filterParkingForm.controls.buildingParking.setValue(undefined);
          this.filterParkingForm.controls.floorParking.setValue(undefined);
          this.spaceList = res.content;
          this.spacePaginationService.setSpacesList(res);
          this.spacePaginationService.initPages();
          this.showMsgSpace = true;
          this.showMsgPark = false;
          this.paginationReset();
        }
      });
  }

  selectParkingFloor(event) {
    this.floorId = event.id;
    this.floorPark = event;
    const requestId = this.card.id;
    const pagination = {
      page: 0,
      size: 20
    };
    this.spacesService.getRequestIdFreeSpaceByFloor({
      requestId,
      spaceType: SpaceType.ParkingPlace,
      floorId: this.floorId,
      pagination})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.parkingList = res.content;
          this.spacePaginationService.setParkingList(res);
          this.spacePaginationService.initPages();
          this.showMsgPark = true;
          this.paginationReset();
        }
      });
  }

  assignFreePlace() {
    const wPlaceId = this.radioSel.id;
    const requestId = this.card.id;
    const pPlaceId = this.radioParkSel ? this.radioParkSel.id : null;
    this.requestService.assignRequestParkingSpaceEmployee({ requestId, wPlaceId, pPlaceId })
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: Reservation) => {
            if (res) {
              this.showAssign.emit(false);
            }
          });
  }

  searchSpaces() {
    if (this.floorId) {
      const id = this.floorId;
      this.floorService.listSpace({ Id: id })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.spaceList = res.content;
        });
    }
  }
  getSelecteditem() {
    this.radioSel = this.spaceList.find(item => item.id.toString() === this.radioSelected);
  }
  onItemChange() {
    this.getSelecteditem();
  }
  getSelectedParkitem() {
    this.radioParkSel = this.parkingList.find(item => item.id.toString() === this.radioParkSelected);
  }
  compareParking(event) {
    if (this.radioParkSel === event) {
      this.radioParkSelected = undefined;
    }
  }
  onParkItemChange() {
    this.getSelectedParkitem();
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  paginationReset() {
    this.infiniteScroll.disabled = false;
    this.onScrollParking(this.eventParking);
  }

  onScrollSpaces(event): void {
    if (this.spacePaginationService.hasMorePages()) {
      this.spacePaginationService.addSpacesToAssign(
        this.card.id,
        this.spacePaginationService.getNextPageNumber(),
        this.floorSpace.id,
        this.card.disabled);
      event.target.complete();
    } else {
      event.target.disabled = true;
    }
  }

  onScrollParking(event): void {
    this.eventParking = event;
    if (event !== undefined) {
      event.target.disabled = false;
      if (this.spacePaginationService.hasMorePagesPark()) {
        this.spacePaginationService.addParkingToAssgin(
        this.card.id,
        this.spacePaginationService.getNextPageNumberParking(),
        this.floorPark.id,
        this.card.disabled);
        event.target.complete();
      } else {
        event.target.disabled = true;
      }
    }
  }
}
