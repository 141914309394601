import { Pipe, PipeTransform } from '@angular/core';
import { QuestionType, QuestionTypeText } from '@shared/constants/survey/enums';
import { TranslationsService } from '@core/services/translations/translations.service';

@Pipe({
  name: 'questionType'
})
export class QuestionTypePipe implements PipeTransform {
  private typesTextTranslated: string[] = [QuestionTypeText.FreeText, QuestionTypeText.YesNo, QuestionTypeText.Rating];

  constructor(private  translationsService: TranslationsService) {
    const arrayTmp = [];
    this.typesTextTranslated.map(type => arrayTmp.push(this.translationsService.instant(type)));
    this.typesTextTranslated = arrayTmp;
  }

  transform(value: any, ...args: any[]): any {
    return this.typesTextTranslated[QuestionType[value]];
  }
}
