/* tslint:disable */
import { Injectable } from '@angular/core';
import { SwaggerSpacesDefinitionsBaseService } from '../swagger-spaces-definitions-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { SwaggerSpacesDefinitionsRequestBuilder } from '../swagger-spaces-definitions-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { KeyValue } from '../models/key-value';


/**
 * Operations about buildings
 */
@Injectable({
  providedIn: 'root',
})
export class BuildingsService extends SwaggerSpacesDefinitionsBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation spacesBuildingGet
   */
  static readonly SpacesBuildingGetPath = '/spaces/building';

  /**
   * All buildings.
   *
   * sdfsadfasdfsad
   *
   * This method provides customization options.
   * To access only the response body, use `spacesBuildingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesBuildingGet$Response(params?: {

  }): Observable<Array<KeyValue>> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(BuildingsService.SpacesBuildingGetPath, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Array<KeyValue>;
      })
    );
  }

  /**
   * All buildings.
   *
   * sdfsadfasdfsad
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `spacesBuildingGet$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  spacesBuildingGet(params?: {

  }): Observable<Array<KeyValue>> {

    return this.spacesBuildingGet$Response(params).pipe(
      map(r => r as Array<KeyValue>)
    );
  }

  /**
   * Path part for operation spacesBuildingIdFloorsGet
   */
  static readonly SpacesBuildingIdFloorsGetPath = '/spaces/building/{Id}/floors';

  /**
   * All Floors in a building.
   *
   * sdfasdfasdfsad
   *
   * This method provides customization options.
   * To access only the response body, use `spacesBuildingIdFloorsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesBuildingIdFloorsGet$Response(params: {
    Id: number;

  }): Observable<Array<KeyValue>> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(BuildingsService.SpacesBuildingIdFloorsGetPath, 'get');
    if (params) {
      req.path('Id', params.Id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Array<KeyValue>;
      })
    );
  }

  /**
   * All Floors in a building.
   *
   * sdfasdfasdfsad
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `spacesBuildingIdFloorsGet$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  spacesBuildingIdFloorsGet(params: {
    Id: number;

  }): Observable<Array<KeyValue>> {

    return this.spacesBuildingIdFloorsGet$Response(params).pipe(
      map(r => r as Array<KeyValue>)
    );
  }

}
