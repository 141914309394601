import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-results-msg',
  templateUrl: './no-results-msg.component.html',
  styleUrls: ['./no-results-msg.component.scss'],
})
export class NoResultsMsgComponent {
  @Input() text;
  @Input() imgPath;
}
