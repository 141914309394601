<!-- CONTAINER ITEM CARD INFO -->
<div (click)="selectedRow()" class="item-card-data">
  <div class="row">
    <div class="col-md-2 col-sm-6">
      <div class="label-txt">
        {{ "SPACE.BUILDING" | translate}}
      </div>
      <div>
        {{building.name}}
      </div>
    </div>
    <div class="col-md-2 col-sm-6">
      <div class="label-txt">
        {{ "SPACE.FLOOR" | translate}}
      </div>
      <div>
        {{floor.name}}
      </div>
    </div>
    <div class="col-md-2 col-sm-6">
      <div class="label-txt">
        {{ "SPACE.DESCRIPTION" | translate}}
      </div>
      <div>
        {{space.name}}
      </div>
    </div>
    <div class="col-md-2 col-sm-6">
      <div class="label-txt">
        {{ "SPACE.STATUS" | translate}}
      </div>
      <div>
        {{status | translate}}
      </div>
    </div>
    <div class="col-md-1 col-sm-6">
      <div class="label-txt">
        {{ "SPACE.COST" | translate}}
      </div>
      <div>
        {{space.price}}
      </div>
    </div>
    <div class="col-md-2 col-sm-6">
      <div class=" d-flex justify-content-center">
        <div class="icon-width-control">
          <i *ngIf="space.type === 'PARKING_PLACE' && space.disabled" class="icon-accessibility-on"></i>
          <ion-icon src="assets/icon/parkingElectric-outline.svg" class="electric-color" *ngIf="space.type === 'PARKING_PLACE' && space?.vElectric"></ion-icon>
          <ion-icon name="people-outline" class="shared-color" *ngIf="space.type === 'PARKING_PLACE' && space?.vShared"></ion-icon> 
        </div>
        <i [ngClass]="{'icon-car': space.type === 'PARKING_PLACE', 'icon-monitor': space.type === 'WORK_PLACE'}"></i>
      </div>
    </div>
    <div class="col-md-1 col-sm-6 border-left p-2 text-center">
      <i class="icon-edit sm p-1 pointer"></i>
    </div>
  </div>
</div>
<!-- CONTAINER ITEM CARD FORM TO EDITING AND CREATING NEW ITEM CARDS -->
<app-space-form (closeShowCard)="closeShowCard($event)" (reloadSearch)="reloadSearch()" *ngIf="showCardDetail" [building]="building"
                [floor]="floor" [showCardDetail]="showCardDetail"
                [spaceDetail]="space"></app-space-form>
