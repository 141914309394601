/* tslint:disable */
import { ApiBaseService } from '../api-base-service';
import { HttpService } from '@core/http/http.service';
import { ConfigService } from '@core/services/config/config.service';

/**
* Base class for API services from a specific API collection
* Changes made here will only affect this API collection, leaving other possible
* ones untouched. For full API catalogue customizations, please refer to ApiBaseService
* or the use of interceptors
*/
export class BeDigitalSurveysApiBaseService extends ApiBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
  super(config, http);
  }
        }
