<input id="typeahead-http" type="text" class="form-control" 
       [(ngModel)]="model"
       (selectItem)="selectItem($event)"
       [ngbTypeahead]="search" 
       [inputFormatter]="formatter"
       [resultFormatter]="formatter"
       placeholder="{{ placeholder | translate }}" />
<i class="icon-close" *ngIf="model" (click)="cleanInput()"></i>
<!-- MENSAJES DE ALERTA -->
<div *ngIf="showMessage" class="alert alert-danger">
       <span [translateParams]="{ minChars: minCharsNumber }" [translate]="errorMessage"></span>
</div>