/* tslint:disable */
import { Injectable } from '@angular/core';
import { SwaggerSpacesDefinitionsBaseService } from '../swagger-spaces-definitions-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { SwaggerSpacesDefinitionsRequestBuilder } from '../swagger-spaces-definitions-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Employee } from '../models/employee';
import { IdType } from '../models/id-type';
import { KeyValue } from '../models/key-value';
import { Reservation } from '../models/reservation';
import { SpaceAsignUser } from '../models/space-asign-user';
import { SpaceUsedCriteria } from '../models/space-used-criteria';
import { SpaceUsedPageResponse } from '../models/space-used-page-response';


/**
 * Operations about employees
 */
@Injectable({
  providedIn: 'root',
})
export class EmployeesService extends SwaggerSpacesDefinitionsBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listEmployeesV2
   */
  static readonly ListEmployeesV2Path = '/employees/list/{name}';

  /**
   * All employees by name (v2).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `listEmployeesV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEmployeesV2$Response(params: {
    name: string;

  }): Observable<Array<KeyValue>> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(EmployeesService.ListEmployeesV2Path, 'get');
    if (params) {
      req.path('name', params.name, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Array<KeyValue>;
      })
    );
  }

  /**
   * All employees by name (v2).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `listEmployeesV2$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  listEmployeesV2(params: {
    name: string;

  }): Observable<Array<KeyValue>> {

    return this.listEmployeesV2$Response(params).pipe(
      map(r => r as Array<KeyValue>)
    );
  }

  /**
   * Path part for operation getEmployeesV2
   */
  static readonly GetEmployeesV2Path = '/employees/{id}';

  /**
   * Get employee by id (v2).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getEmployeesV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesV2$Response(params: {
    id: number;

  }): Observable<Employee> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(EmployeesService.GetEmployeesV2Path, 'get');
    if (params) {
      req.path('id', params.id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Employee;
      })
    );
  }

  /**
   * Get employee by id (v2).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getEmployeesV2$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesV2(params: {
    id: number;

  }): Observable<Employee> {

    return this.getEmployeesV2$Response(params).pipe(
      map(r => r as Employee)
    );
  }

  /**
   * Path part for operation listEmployeeSpaces
   */
  static readonly ListEmployeeSpacesPath = '/spaces/employee/spaces/{page}';

  /**
   * All spaces used by a employee by admin (Deprecated, not used).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `listEmployeeSpaces()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listEmployeeSpaces$Response(params: {

    /**
     * page id
     */
    page: number;
  
    /**
     * Search criteria
     */
    body: SpaceUsedCriteria
  }): Observable<SpaceUsedPageResponse> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(EmployeesService.ListEmployeeSpacesPath, 'post');
    if (params) {
      req.path('page', params.page, {});
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as SpaceUsedPageResponse;
      })
    );
  }

  /**
   * All spaces used by a employee by admin (Deprecated, not used).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `listEmployeeSpaces$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listEmployeeSpaces(params: {

    /**
     * page id
     */
    page: number;
  
    /**
     * Search criteria
     */
    body: SpaceUsedCriteria
  }): Observable<SpaceUsedPageResponse> {

    return this.listEmployeeSpaces$Response(params).pipe(
      map(r => r as SpaceUsedPageResponse)
    );
  }

  /**
   * Path part for operation assignSpaceEmployee
   */
  static readonly AssignSpaceEmployeePath = '/spaces/employee/{Id}/assign';

  /**
   * Assign a free space type WorkPlace, Parking (both of them) to an employee for a day by admin (Deprecated, not used).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `assignSpaceEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignSpaceEmployee$Response(params: {

    /**
     * employee id
     */
    Id: IdType;
  
    /**
     * Assign criteria
     */
    body: SpaceAsignUser
  }): Observable<Reservation> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(EmployeesService.AssignSpaceEmployeePath, 'post');
    if (params) {
      req.path('Id', params.Id, {});
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Reservation;
      })
    );
  }

  /**
   * Assign a free space type WorkPlace, Parking (both of them) to an employee for a day by admin (Deprecated, not used).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `assignSpaceEmployee$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignSpaceEmployee(params: {

    /**
     * employee id
     */
    Id: IdType;
  
    /**
     * Assign criteria
     */
    body: SpaceAsignUser
  }): Observable<Reservation> {

    return this.assignSpaceEmployee$Response(params).pipe(
      map(r => r as Reservation)
    );
  }

}
