import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppUrls } from '@config/app-urls.config';
import { AuthGuard } from '@core/guards/auth/auth.guard';

// This module will handle the main app routes, instead of app module (for better decoupling)

const routes: Routes = [
  {
    path: AppUrls.AppHome,
    loadChildren: () => import('@views/home/home.module').then(m => m.HomeViewModule),
    canActivate: [AuthGuard]
  },
  {
    path: AppUrls.AppLogin,
    loadChildren: () => import('@views/login/login.module').then(m => m.LoginModule)
  },
  {
    path: AppUrls.AppSpaceManager,
    loadChildren: () => import('@views/space-manager/space-manager.module').then(m => m.SpaceManagerModule),
    canActivate: [AuthGuard]
  },
  {
    path: AppUrls.AppSurveyManager,
    loadChildren: () => import('@views/survey-manager/survey-manager.module').then(m => m.SurveyViewModule),
    canActivate: [AuthGuard]
  },
  {
    // Now redirect all unknown routes to login instead of home
    path: '**',
    redirectTo: AppUrls.AppLogin,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class CoreRoutingModule {}
