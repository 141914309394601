<div class="box-section-img"*ngFor="let section of sectionList">
  <div class="box-section-img-img">
    <img  class="img-fluid" [src]="imgUrl" alt=""/>
  </div>
  <div class="d-flex flex-column">
    <div class="sub-title">
      {{ section.subtitle }}
    </div>
    <h1 class="title">
      {{ title | translate }}
    </h1>
    <div class="txt">
      {{ section.text }}
    </div>
  </div>
</div>