import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {

  @Input() breadcrumb;
  @Output() breadcrumbRoute: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  navigate(level) {
    this.breadcrumbRoute.emit(level);
  }

}
