import { FilterComponent } from './components/filter/filter.component';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { DemoDirective } from './directives/demo/demo.directive';
import { DemoComponent } from './components/demo/demo.component';
import { DemoPipe } from './pipes/demo/demo.pipe';
import { BoxSectionImgComponent } from './components/box-section-img/box-section-img.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BoxSectionSmallComponent } from './components/box-section-small/box-section-small.component';
import { BtnBlockBottomComponent } from './components/btn-block-bottom/btn-block-bottom.component';
import { StepBarNavComponent } from './components/step-bar-nav/step-bar-nav.component';
import { SpaceFormComponent } from '@app/shared/components/space-form/space-form.component';
import { SpaceCardComponent } from '@app/shared/components/space-card/space-card.component';
import { SpacesPipe } from './pipes/spaces/spaces.pipe';
import { YesNoPipe } from './pipes/yes-no/yes-no.pipe';
import { QuestionTypePipe } from './pipes/question-type/question-type.pipe';
import { InfiniteScrollComponent } from '@shared/components/infinite-scroll/infinite-scroll.component';
import { GenericModalModule } from '@shared/modules/generic-modal/generic-modal.module';
import { NoResultsMsgComponent } from '@shared/components/no-results-msg/no-results-msg.component';
import { SpaceAssignCardComponent } from '@app/shared/components/space-assign-card/space-assign-card.component';
import { SpaceUserAssignComponent } from '@app/shared/components/space-user-assign/space-user-assign.component';
// import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

// Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Ionic
import { IonicArqModule } from '@app/ionic.module';
import { FilterFromUrlComponent } from './components/filter-from-url/filter-from-url.component';
import { PowerBIComponent } from './components/power-bi/power-bi.component';

/**
 * This module should not contain providers (they'd be on core module)
 * and must be imported on every module from (+modules) so as
 * to use its elements (components, directives and pipes)
 *
 * This module also exports Ionic elements from IonicArqModule,
 * Flex Layout and Material/Bootstrap resources from
 * visuals module, so it is mandatory to import it on every module
 * from (+views) that needs to use them
 */
@NgModule({
  declarations: [/* Components, directives and pipes to be shared */
    DemoDirective,
    DemoComponent,
    DemoPipe,
    BoxSectionImgComponent,
    BreadcrumbComponent,
    FilterComponent,
    SpaceCardComponent,
    SpaceFormComponent,
    BoxSectionSmallComponent,
    SpacesPipe,
    BtnBlockBottomComponent,
    StepBarNavComponent,
    YesNoPipe,
    QuestionTypePipe,
    PowerBIComponent,
    InfiniteScrollComponent,
    SpaceAssignCardComponent,
    SpaceUserAssignComponent,
    FilterFromUrlComponent,
    NoResultsMsgComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    IonicArqModule,
    TranslateModule,
    GenericModalModule,
    HttpClientModule,
    NgbModule
  ],
  exports: [/* Components, directives and pipes to be shared */
    DemoComponent,
    DemoDirective,
    DemoPipe,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    IonicArqModule,
    TranslateModule,
    BoxSectionImgComponent,
    BreadcrumbComponent,
    FilterComponent,
    SpaceCardComponent,
    SpaceFormComponent,
    BoxSectionSmallComponent,
    SpacesPipe,
    BtnBlockBottomComponent,
    StepBarNavComponent,
    YesNoPipe,
    QuestionTypePipe,
    PowerBIComponent,
    InfiniteScrollComponent,
    SpaceAssignCardComponent,
    SpaceUserAssignComponent,
    FilterFromUrlComponent,
    NoResultsMsgComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [/* In case (rare) we want to share providers too, we'll use the .forRoot() method when importing the module*/]
    };
  }
}
