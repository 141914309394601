import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpacesService } from '@app/core/api/SWAGGER-SPACES-DEFINITIONS/services';
import { SpaceLogicService } from '@core/services/space-logic/space-logic.service';

@Component({
  selector: 'app-space-card',
  templateUrl: './space-card.component.html',
  styleUrls: ['./space-card.component.scss'],
})
export class SpaceCardComponent implements OnInit, OnDestroy {

  showCardDetail = false;
  floor;
  status;
  card;
  @Input() space: any;
  @Input() floors;
  @Input() buildings;
  @Input() building;
  @Output() reload: EventEmitter<any> = new EventEmitter();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private spaceService: SpacesService,
              private spaceLogicService: SpaceLogicService) { }

  ngOnInit() {
    this.spaceLogicService.floors.forEach(element => {
      if (element.id === this.space.floorId){
        this.floor = element;
      }
    });
    this.status = (this.space.status ? 'KEY.OK' : 'KEY.KO');
  }

  selectedRow() {
    this.showCardDetail = !this.showCardDetail;

    if (this.showCardDetail){
      const id = this.space.id;
      this.spaceLogicService.getSpaceById({ Id: id })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.space = res;
         });
    }
  }

  closeShowCard(event) {
    this.showCardDetail = event;
  }

  reloadSearch() {
    const id = this.card.id;
    this.spaceService.getSpace({ Id: id })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.card = res;
         });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
