import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { AppUrls } from '@config/app-urls.config';
import { take } from 'rxjs/operators';
import { Config } from '@core/models/envConfig/config.interface';

@Injectable()
export class ConfigService {

  private http: HttpClient;
  public config;
  public mitmScriptDependency;

  constructor(
    private readonly handler: HttpBackend
  ) {
  }

  getConfig() {
    return new Promise(res => {
      if (this.config) {
        res(this.config as Config);
        return;
      }
      this.http = new HttpClient(this.handler);
      this.http.get(AppUrls.Config)
        .pipe(take(1))
        .subscribe(
          config => {
            this.config = config;
            res(this.config as Config);
          }
        );
    });
  }

  getEndpoints(apiName: string) {
    if (!this.config) {
      return;
    }
    return this.config.app.rest[apiName].endpoints;
  }

  getProperty(propName: string) {
    if (!this.config) {
      return;
    }

    propName = propName
      .replace(/\["|\['/g, '.')       // When referencing using brackets, replace them with a dot
      .replace(/^\./, '')             // Remove possible characters at the beginning of the string
      .replace(/"\]|'\]/g, '');
    const arr = propName.split('.');
    let conf = this.config;
    for (const el of arr) {
      if (el in conf) {
        conf = conf[el];
      } else {
        return;
      }
    }
    return conf;
  }

  // TODO review this setter, since now it's just a provisional solution and may throw errors iterating over arrays or other cases
  setConfig(field: string, value: string | object): Promise<any> {
    return new Promise(res => {
      res(this.recursiveReplace(this.config, field, value));
    });
  }

  // TODO take this helper function to utils service
  private recursiveReplace(inputObject, field, value) {
    Object.keys(inputObject).forEach(key => {
      if (key === field) {
        inputObject[key] = value;
      }
      if (inputObject.hasOwnProperty(key) && typeof inputObject[key] === 'object') {
        this.recursiveReplace(inputObject[key], field, value);
      }
    });
  }
}
