<app-header></app-header>
<div class="container">
  <router-outlet></router-outlet>
</div>
<app-loader></app-loader>
<app-footer></app-footer>
<!--
<ion-app>
  <ion-router-outlet></ion-router-outlet>
</ion-app> -->
