import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-box-section-img',
  templateUrl: './box-section-img.component.html',
  styleUrls: ['./box-section-img.component.scss'],
})
export class BoxSectionImgComponent implements OnInit {
  @Input() title;
  @Input() imgUrl;

  sectionList = [
    {
      subtitle: 'Tu portal de administración',
      text: 'Accede a un servicio integral',
    },
  ];

  constructor() { }

  ngOnInit() {}

}
