import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '@core/services/config/config.service';
import { Config } from '@core/models/envConfig/config.interface';
import { StorageService } from '@core/services/storage/storage.service';

/**
 * Translation service that uses ngx-translate
 * It relies on localStorage so as to provide consistent language experience
 * (pending on defining if it should be session-based, and use sessionStorage instead)
 */
@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  public langSelected: string;
  private envConfig: Config;
  private langDefault = 'es';
  private langStore = 'language';

  /**
   * Sets default language
   * It first considers the language set on environment configs, and goes for default if none is found
   */
  constructor(
    private readonly config: ConfigService,
    private readonly translateService: TranslateService,
    private readonly storage: StorageService
  ) {
    this.envConfig = this.config.config;
    this.storage.set(this.langStore, this.envConfig.app.properties.language || this.langDefault);
  }

  /**
   * Returns selected language
   */
  public getLanguageSelected(): string {
    return this.langSelected;
  }

  /**
   * Initializes a language. It checks if it has been already set
   * and initializes default language otherwise
   */
  public init(): string {
    if (this.langSelected) {
      return this.langSelected;
    }

    this.storage.get(this.langStore)
      .then(
        lang => this.setLanguage(lang || this.langDefault)
      )
      .catch(
        () => this.setLanguage(this.langDefault)
      );

    return this.langSelected;
  }

  /**
   * Returns instant translation
   * @param key: string
   */
  public instant(key: string) {
    return this.translateService.instant(key);
  }

  /**
   * Sets language considering "lang" param
   * @param lang: string
   */
  public setLanguage(lang: string) {
    this.storage.set(this.langStore, lang);
    this.langSelected = lang;
    this.translateService.use(lang);
  }
}
