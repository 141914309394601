import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BuildingsService, EmployeesService, SpacesService } from '@app/core/api/SWAGGER-SPACES-DEFINITIONS/services';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TranslationsService } from '@app/core/services/translations/translations.service';
import { GenericModalComponent } from '@shared/modules/generic-modal/generic-modal.component';
import { SpaceLogicService } from '@core/services/space-logic/space-logic.service';
import { SpaceCreation } from '@core/api/SWAGGER-SPACES-DEFINITIONS/models/space-creation';
import { SpaceType } from '@core/api/SWAGGER-SPACES-DEFINITIONS/models/space-type';
import { Space } from '@core/api/SWAGGER-SPACES-DEFINITIONS/models/space';

enum InputType {
  Accessibility,
  Electric,
  Shared
}

@Component({
  selector: 'app-space-form',
  templateUrl: './space-form.component.html',
  styleUrls: ['./space-form.component.scss']
})
export class SpaceFormComponent implements OnInit, OnDestroy {
  spaceForm: FormGroup;
  newSpace: SpaceCreation;
  employeeId: any;
  cost;
  typeSpace: SpaceType = SpaceType.ParkingPlace;
  description;
  employeeToModify: BehaviorSubject<any> = new BehaviorSubject({});
  InputType = InputType;
  @Input() showCardDetail;
  @Input() buildings;
  @Input() spaceDetail: Space;
  @Input() floor;
  @Input() building;
  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() reloadSearch: EventEmitter<any> = new EventEmitter();
  @Output() closeShowCard: EventEmitter<any> = new EventEmitter();
  buildingsGet: any;
  floors: any;
  status = [
    this.translateService.instant('KEY.OK'), this.translateService.instant('KEY.KO')
  ];
  typespace = [
    this.translateService.instant('KEY.WORK_PLACE'), this.translateService.instant('KEY.PARKING_PLACE')
  ];
  selectValue = [
    this.translateService.instant('KEY.YES'),
    this.translateService.instant('KEY.NO')
  ];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private activeInput: InputType;

  constructor(
    private modalController: ModalController,
    private translateService: TranslationsService,
    private spaceService: SpacesService,
    private buildingService: BuildingsService,
    private employeesService: EmployeesService,
    private spaceLogicService: SpaceLogicService
  ) {
  }

  ngOnInit() {
    this.spaceForm = new FormGroup({
      building: new FormControl({ value: null, disabled: this.showCardDetail }, Validators.required),
      typespace: new FormControl({ value: null, disabled: this.showCardDetail }, Validators.required),
      floor: new FormControl({ value: null, disabled: this.showCardDetail }, Validators.required),
      cost: new FormControl({ value: null }, Validators.required),
      status: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      accessibility: new FormControl({
        value: null,
        disabled: this.showCardDetail && this.spaceDetail.type === SpaceType.WorkPlace
      }, Validators.required),
      sharedSpace: new FormControl(null, Validators.required),
      electricSpace: new FormControl(null, Validators.required)
    });
    this.buildingsGet = this.buildings;

    if (this.showCardDetail) {
      this.spaceForm.controls.status.setValue(this.spaceDetail.status ?
        this.translateService.instant('KEY.OK') : this.translateService.instant('KEY.KO'));
      this.spaceForm.get('accessibility').setValue(this.spaceDetail.disabled ?
        this.translateService.instant('KEY.YES') : this.translateService.instant('KEY.NO'));
      this.spaceForm.get('description').setValue(this.spaceDetail.name);
      this.spaceForm.get('floor').setValue(this.floor.name);
      this.spaceForm.get('building').setValue(this.building.name);
      this.spaceForm.get('cost').setValue(this.spaceDetail.price);
      this.spaceForm.controls.typespace.setValue(this.spaceDetail.type === SpaceType.WorkPlace ?
        this.translateService.instant('KEY.WORK_PLACE') : this.translateService.instant('KEY.PARKING_PLACE'));
      this.spaceForm.get('sharedSpace').setValue(this.spaceDetail.vShared ?
        this.translateService.instant('KEY.YES') : this.translateService.instant('KEY.NO'));
      this.spaceForm.get('electricSpace').setValue(this.spaceDetail.vElectric ?
        this.translateService.instant('KEY.YES') : this.translateService.instant('KEY.NO'));
      if (this.spaceDetail.type === SpaceType.WorkPlace) {
        this.spaceForm.get('cost').disable();
        this.spaceForm.get('accessibility').setValue(this.translateService.instant('KEY.YES'));
        const newEmployeeId = sessionStorage.getItem('newEmployeeId') ? JSON.parse(sessionStorage.getItem('newEmployeeId')) : 0;
        this.getEmployeeName(newEmployeeId ? newEmployeeId : this.spaceDetail.employeeId);
        sessionStorage.setItem('newEmployeeId', '0');
      }
    }
  }

  getEmployeeName(employeeId: number) {
    if (employeeId) {
      this.employeesService.getEmployeesV2({ id: employeeId })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.employeeToModify.next({ id: employeeId, name: res.name });
        });
    }
  }

  selectBuilding(event) {
    const id = event.id;
    this.buildingService.spacesBuildingIdFloorsGet({ Id: id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.floors = res;
        }
      });
  }

  selectedEmployee(ev) {
    this.employeeId = ev.id ? ev.id.toString() : '';
  }

  formatForm(type) {
    if (this.spaceForm.controls.typespace.value === this.translateService.instant('KEY.WORK_PLACE')) {
      this.typeSpace = SpaceType.WorkPlace;
    }
    this.spaceForm.addControl('building', new FormControl(Validators.required));
    this.spaceForm.addControl('typespace', new FormControl(Validators.required));
    this.spaceForm.addControl('floor', new FormControl(Validators.required));
    this.spaceForm.addControl('cost', new FormControl({ value: this.cost }, Validators.required));
    this.spaceForm.addControl('status', new FormControl(Validators.required));
    this.spaceForm.addControl('description', new FormControl({ value: this.description }, Validators.required));
    this.spaceForm.addControl('accessibility', new FormControl(Validators.required));
    if (type === 'create') {
      this.newSpace = {
        disabled: false,
        name: this.spaceForm.get('description').value,
        type: this.typeSpace,
        floorId: this.spaceForm.get('floor').value.id,
        status: this.spaceForm.controls.status.value === this.translateService.instant('KEY.OK')
      };
    } else {
      this.newSpace = {
        disabled: false,
        floorId: undefined,
        type: undefined,
        name: this.spaceForm.get('description').value,
        status: this.spaceForm.controls.status.value === this.translateService.instant('KEY.OK')
      };
    }

    if (this.employeeId) {
      this.newSpace.employeeId = this.employeeId;
    }

    if (this.spaceForm.controls.typespace.value === this.translateService.instant('KEY.PARKING_PLACE')) {
      this.newSpace.disabled = this.spaceForm.get('accessibility').value === this.translateService.instant('KEY.YES');
      this.newSpace.price = this.spaceForm.get('cost').value;
      this.newSpace.vShared = this.spaceForm.get('sharedSpace').value === this.translateService.instant('KEY.YES');
      this.newSpace.vElectric = this.spaceForm.get('electricSpace').value === this.translateService.instant('KEY.YES');
    }
  }

  createSpace() {
    this.formatForm('create');
    this.presentModal();
  }

  changeType(type) {
    this.spaceForm.get('cost').reset();
    this.spaceForm.get('accessibility').reset();
    if (type === this.translateService.instant('KEY.WORK_PLACE')) {
      this.spaceForm.get('cost').disable();
      this.spaceForm.get('accessibility').disable();
      this.spaceForm.get('sharedSpace').disable();
      this.spaceForm.get('electricSpace').disable();
      this.resetParkingInputValues();
    } else {
      this.spaceForm.get('cost').enable();
      this.spaceForm.get('accessibility').enable();
      this.spaceForm.get('sharedSpace').enable();
      this.spaceForm.get('electricSpace').enable();
    }
  }

  isAvailableStatus(): boolean {
    return this.spaceForm.controls.status.value === this.translateService.instant('KEY.OK');
  }

  isWorkSpaceType(): boolean {
    return this.spaceForm.controls.typespace.value === this.translateService.instant('KEY.WORK_PLACE');
  }

  modifySpace() {
    this.formatForm('modify');
    const body = this.newSpace;
    const id = this.spaceDetail.id;

    this.updateSpaceStore();

    // this.spaceService.patchSpace({ Id, body })
    this.spaceLogicService.patchSpace({ Id: id, body })
      .pipe(
        take(1)
      )
      .subscribe((data: any) => {
        sessionStorage.setItem('newEmployeeId', data.employeeId);
        this.successModal();
        this.showCardDetail = !this.showCardDetail;
        this.closeShowCard.emit(this.showCardDetail);
      });
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: GenericModalComponent,
      cssClass: 'success-msg-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        firstTitle: 'MODAL.TITLE_CREATE_SPACE',
        text: 'MODAL.CHECK_CREATE_SPACE',
        firstButton: { text: 'BUTTON.YES', action: 'create' },
        secondButton: { text: 'BUTTON.NO' }
      }
    });

    await modal.present();

    modal.onWillDismiss().then((modalData) => {
      if (modalData.data.dismissed && modalData.data.info.actionButton === 'create') {
        if (this.create) {
          const body = this.newSpace;
          this.spaceService.createSpace({ body })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
              this.employeeToModify.next({ id: '', name: '' });
              sessionStorage.setItem('newEmployeeId', res.employeeId);
              this.successModal();
            });
          this.create.emit(this.newSpace);
          this.spaceForm.reset();
        }
      }
    });
  }

  async successModal() {
    const modal = await this.modalController.create({
      component: GenericModalComponent,
      cssClass: 'success-msg-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        text: 'SUCCESS_MSG.OK',
        iconToShow: 'icon-ok',
        firstButton: {
          text: 'BUTTON.ACCEPT'
        }
      }
    });

    await modal.present();
  }

  closeDetail() {
    this.showCardDetail = !this.showCardDetail;
    this.closeShowCard.emit(this.showCardDetail);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private updateSpaceStore(): void {
    this.spaceDetail = {
      ...this.spaceDetail,
      disabled: this.newSpace.disabled,
      name: this.newSpace.name,
      price: this.newSpace.price,
      status: this.newSpace.status
    };

    const spacesUpdated = this.spaceLogicService.spaces;
    console.info('spacesUpdated', spacesUpdated);

    const spaceToModifyIndex = spacesUpdated.findIndex(space => space.id === this.spaceDetail.id);
    spacesUpdated[spaceToModifyIndex] = {
      ...spacesUpdated[spaceToModifyIndex],
      disabled: this.newSpace.disabled,
      name: this.newSpace.name,
      price: this.newSpace.price,
      status: this.newSpace.status,
      vElectric: this.newSpace.vElectric,
      vShared: this.newSpace.vShared
    };

    this.spaceLogicService.spaces = spacesUpdated;
  }

  checkInputsCompatibility(event: any, typeSelected: InputType): void {
    if (event === this.translateService.instant('KEY.YES')) {
      this.activeInput = typeSelected;
    }

    if (event === this.translateService.instant('KEY.YES')) {
      switch (typeSelected) {
        case InputType.Accessibility:
          this.spaceForm.get('electricSpace').setValue(this.translateService.instant('KEY.NO'), { emitEvent: false });
          this.spaceForm.get('sharedSpace').setValue(this.translateService.instant('KEY.NO'), { emitEvent: false });
          break;
        case InputType.Electric:
          this.spaceForm.get('accessibility').setValue(this.translateService.instant('KEY.NO'), { emitEvent: false });
          this.spaceForm.get('sharedSpace').setValue(this.translateService.instant('KEY.NO'), { emitEvent: false });
          break;
        case InputType.Shared:
          this.spaceForm.get('accessibility').setValue(this.translateService.instant('KEY.NO'), { emitEvent: false });
          this.spaceForm.get('electricSpace').setValue(this.translateService.instant('KEY.NO'), { emitEvent: false });
          break;
      }
    }
  }

  private resetParkingInputValues(): void {
    this.spaceForm.get('accessibility').setValue(null);
    this.spaceForm.get('sharedSpace').setValue(null);
    this.spaceForm.get('electricSpace').setValue(null);
  }
}
