import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-box-section-small',
  templateUrl: './box-section-small.component.html',
  styleUrls: ['./box-section-small.component.scss'],
})
export class BoxSectionSmallComponent implements OnInit {

  @Input() title: string;
  @Input() icon: string;

  constructor() { }

  ngOnInit() { }

}
