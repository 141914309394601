import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { StorageService } from '@app/core/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class MiddlewareService {

  private STORAGE_FLOW_KEY = 'BDGtal_flow';

  constructor(
    private confService: ConfigService,
    private storage: StorageService
  ) {
  }

  getFlowId(): string {
    return this.confService.getProperty('app.uniqueFlow.id');
  }

  geturlFlowActive(): boolean {
    return this.confService.getProperty('app.uniqueFlow.urlFlowActive');
  }

  getStateManualAssign(): boolean {
    return this.confService.getProperty('app.uniqueFlow.enableManualAssign');
  }

  async getFlowIdFromStorage(): Promise<string> {
    return await this.storage.get(this.STORAGE_FLOW_KEY);
  }

  setFlowInStorageById(id: string) {
    this.storage.set(this.STORAGE_FLOW_KEY, id);
  }

  removeFlowOfStorage() {
    this.storage.remove(this.STORAGE_FLOW_KEY);
  }

  getBaseUrlGatewayIfNecessary(requestUrl: string): string {

    if (this.geturlFlowActive()) {
      return this.confService.getProperty('app.baseUrlGateway') + '/' + this.getFlowId();
    }

    return requestUrl;
  }
}
