import { Injectable } from '@angular/core';
import { ConfigService } from '@core/services/config/config.service';
import { EnvOAuth } from '@core/models/envConfig/env-oauth.interface';
import { Plugins, registerWebPlugin } from '@capacitor/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';

import { OAuth2 as OAuth2Plugin } from '@ionic-experis/oauth2';
const { OAuth2 } = Plugins;

@Injectable()
export class AuthService {
  isLoggedIn$ = new BehaviorSubject(false);
  private oauth2Config: EnvOAuth;

  constructor(
    private config: ConfigService,
    private platform: Platform
  ) {
    // TODO review Capacitor versions since different ones on the plugin and the project cause conflicts
    // @ts-ignore
    registerWebPlugin(OAuth2Plugin);
  }

  public getAccessToken$(): Observable<any> {
    return from(this.oauthGetToken());
  }

  public async init(): Promise<any> {
    const isMobileWeb = this.platform.is('mobileweb');
    // Provisional control since the plugin still doesn't have native init method (or doesn't need it)
    if (!isMobileWeb && (this.platform.is('android') || this.platform.is('ios'))) {
      await Promise.resolve();
      return;
    }
    try {
      await OAuth2.init(this.platformConfig());
    } catch (e) {
      await Promise.reject(e);
    }
  }

  public async oauthGetToken(): Promise<any> {
    try {
      const accessTokenResponse = await OAuth2.getToken();
      this.isLoggedIn$.next(true);
      return accessTokenResponse.accessToken;
    } catch (e) {
      this.isLoggedIn$.next(false);
      await Promise.reject(e);
    }
  }

  public async oAuthLogin(): Promise<any> {
    return new Promise(async (res, rej) => {
      OAuth2.authenticate(this.platformConfig())
        .then(() => {
          this.isLoggedIn$.next(true);
          res(true);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  public async oAuthLogout(): Promise<any> {
    return new Promise(async res => {
      this.isLoggedIn$.next(false);
      await OAuth2.logout();
      res(true);
    });
  }

  private platformConfig(): EnvOAuth {
    this.oauth2Config = this.config.config.security;
    const isMobileWeb = this.platform.is('mobileweb');
    if (this.platform.is('android') && !isMobileWeb) {
      this.oauth2Config.redirectUrl = this.oauth2Config.android.redirectUrl;
      this.oauth2Config.responseType = this.oauth2Config.android.responseType;
    }
    if (this.platform.is('ios') && !isMobileWeb) {
      this.oauth2Config.redirectUrl = this.oauth2Config.ios.redirectUrl;
      this.oauth2Config.responseType = this.oauth2Config.ios.responseType;
    }
    return this.oauth2Config;
  }
}
