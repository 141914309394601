/* tslint:disable */
import { Injectable } from '@angular/core';
import { SwaggerSpacesDefinitionsBaseService } from '../swagger-spaces-definitions-base-service';
import { ConfigService } from '@core/services/config/config.service';
import { SwaggerSpacesDefinitionsRequestBuilder } from '../swagger-spaces-definitions-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { IdType } from '../models/id-type';
import { PageableRequest } from '../models/pageable-request';
import { ParkingAssignUser } from '../models/parking-assign-user';
import { Reservation } from '../models/reservation';
import { SpaceType } from '../models/space-type';
import { WaitingList } from '../models/waiting-list';
import { WaitingListPageResponse } from '../models/waiting-list-page-response';


/**
 * Operations about employees spaces requests
 */
@Injectable({
  providedIn: 'root',
})
export class RequestsService extends SwaggerSpacesDefinitionsBaseService {
  constructor(
    config: ConfigService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listRequestTypeSpaceEmployee
   */
  static readonly ListRequestTypeSpaceEmployeePath = '/spaces/request';

  /**
   * List of all request for next day paginated.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `listRequestTypeSpaceEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRequestTypeSpaceEmployee$Response(params?: {

    /**
     * Pagination and sort
     */
    pagination?: PageableRequest;

  }): Observable<WaitingListPageResponse> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(RequestsService.ListRequestTypeSpaceEmployeePath, 'get');
    if (params) {
      req.query('pagination', params.pagination, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as WaitingListPageResponse;
      })
    );
  }

  /**
   * List of all request for next day paginated.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `listRequestTypeSpaceEmployee$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  listRequestTypeSpaceEmployee(params?: {

    /**
     * Pagination and sort
     */
    pagination?: PageableRequest;

  }): Observable<WaitingListPageResponse> {

    return this.listRequestTypeSpaceEmployee$Response(params).pipe(
      map(r => r as WaitingListPageResponse)
    );
  }

  /**
   * Path part for operation postEmployeesWaitingList
   */
  static readonly PostEmployeesWaitingListPath = '/spaces/request';

  /**
   * Add current employee to de waiting list by criteria.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `postEmployeesWaitingList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postEmployeesWaitingList$Response(params: {

    /**
     * Waiting list criteria. If type is WORK_PLACE disabled has no effects.
     */
    body: ParkingAssignUser
  }): Observable<WaitingList> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(RequestsService.PostEmployeesWaitingListPath, 'post');
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as WaitingList;
      })
    );
  }

  /**
   * Add current employee to de waiting list by criteria.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `postEmployeesWaitingList$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postEmployeesWaitingList(params: {

    /**
     * Waiting list criteria. If type is WORK_PLACE disabled has no effects.
     */
    body: ParkingAssignUser
  }): Observable<WaitingList> {

    return this.postEmployeesWaitingList$Response(params).pipe(
      map(r => r as WaitingList)
    );
  }

  /**
   * Path part for operation deleteRequestSpacesEmployee
   */
  static readonly DeleteRequestSpacesEmployeePath = '/spaces/request/{Id}';

  /**
   * Delete request space type to current employee for a day by id.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `deleteRequestSpacesEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRequestSpacesEmployee$Response(params: {

    /**
     * reservation id
     */
    Id: IdType;

  }): Observable<void> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(RequestsService.DeleteRequestSpacesEmployeePath, 'delete');
    if (params) {
      req.path('Id', params.Id, {});
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Delete request space type to current employee for a day by id.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `deleteRequestSpacesEmployee$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRequestSpacesEmployee(params: {

    /**
     * reservation id
     */
    Id: IdType;

  }): Observable<void> {

    return this.deleteRequestSpacesEmployee$Response(params).pipe(
      map(r => r as void)
    );
  }

  /**
   * Path part for operation deleteRequestTypeSpaceEmployee
   */
  static readonly DeleteRequestTypeSpaceEmployeePath = '/spaces/request/{id}/{spaceType}';

  /**
   * Delete request space type to current employee for a day by id.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `deleteRequestTypeSpaceEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRequestTypeSpaceEmployee$Response(params: {

    /**
     * reservation id
     */
    id: IdType;

    /**
     * reservation id
     */
    spaceType: SpaceType;

  }): Observable<void> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(RequestsService.DeleteRequestTypeSpaceEmployeePath, 'delete');
    if (params) {
      req.path('id', params.id, {});
      req.path('spaceType', params.spaceType, {});
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Delete request space type to current employee for a day by id.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `deleteRequestTypeSpaceEmployee$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRequestTypeSpaceEmployee(params: {

    /**
     * reservation id
     */
    id: IdType;

    /**
     * reservation id
     */
    spaceType: SpaceType;

  }): Observable<void> {

    return this.deleteRequestTypeSpaceEmployee$Response(params).pipe(
      map(r => r as void)
    );
  }

  /**
   * Path part for operation assignRequestWorkSpaceEmployee
   */
  static readonly AssignRequestWorkSpaceEmployeePath = '/spaces/request/{requestId}/assign/{wPlaceId}';

  /**
   * Assign a free space type WorkPlace to an employee for a day by admin.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `assignRequestWorkSpaceEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignRequestWorkSpaceEmployee$Response(params: {

    /**
     * request id
     */
    requestId: IdType;

    /**
     * Workplace id
     */
    wPlaceId: IdType;

  }): Observable<Reservation> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(RequestsService.AssignRequestWorkSpaceEmployeePath, 'put');
    if (params) {
      req.path('requestId', params.requestId, {});
      req.path('wPlaceId', params.wPlaceId, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Reservation;
      })
    );
  }

  /**
   * Assign a free space type WorkPlace to an employee for a day by admin.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `assignRequestWorkSpaceEmployee$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  assignRequestWorkSpaceEmployee(params: {

    /**
     * request id
     */
    requestId: IdType;

    /**
     * Workplace id
     */
    wPlaceId: IdType;

  }): Observable<Reservation> {

    return this.assignRequestWorkSpaceEmployee$Response(params).pipe(
      map(r => r as Reservation)
    );
  }

  /**
   * Path part for operation assignRequestParkingSpaceEmployee
   */
  static readonly AssignRequestParkingSpaceEmployeePath = '/spaces/request/{requestId}/assign/{wPlaceId}/{pPlaceId}';

  /**
   * Assign a free space of type WorkPlace and Parking to an employee for a day by admin.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `assignRequestParkingSpaceEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignRequestParkingSpaceEmployee$Response(params: {

    /**
     * request id
     */
    requestId: IdType;

    /**
     * Workplace id
     */
    wPlaceId: IdType;

    /**
     * ParkingPlace id
     */
    pPlaceId: IdType;

  }): Observable<Reservation> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(RequestsService.AssignRequestParkingSpaceEmployeePath, 'put');
    if (params) {
      req.path('requestId', params.requestId, {});
      req.path('wPlaceId', params.wPlaceId, {});
      req.path('pPlaceId', params.pPlaceId, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Reservation;
      })
    );
  }

  /**
   * Assign a free space of type WorkPlace and Parking to an employee for a day by admin.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `assignRequestParkingSpaceEmployee$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  assignRequestParkingSpaceEmployee(params: {

    /**
     * request id
     */
    requestId: IdType;

    /**
     * Workplace id
     */
    wPlaceId: IdType;

    /**
     * ParkingPlace id
     */
    pPlaceId: IdType;

  }): Observable<Reservation> {

    return this.assignRequestParkingSpaceEmployee$Response(params).pipe(
      map(r => r as Reservation)
    );
  }

  /**
   * Path part for operation assignRequestSpaceEmployee
   */
  static readonly AssignRequestSpaceEmployeePath = '/spaces/request/{id}/assign';

  /**
   * Assign a free space type WorkPlace, Parking (both of them) to an employee for a day by admin (Deprecated).
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `assignRequestSpaceEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignRequestSpaceEmployee$Response(params: {

    /**
     * request id
     */
    id: IdType;

  }): Observable<Reservation> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(RequestsService.AssignRequestSpaceEmployeePath, 'put');
    if (params) {
      req.path('id', params.id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Reservation;
      })
    );
  }

  /**
   * Assign a free space type WorkPlace, Parking (both of them) to an employee for a day by admin (Deprecated).
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `assignRequestSpaceEmployee$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  assignRequestSpaceEmployee(params: {

    /**
     * request id
     */
    id: IdType;

  }): Observable<Reservation> {

    return this.assignRequestSpaceEmployee$Response(params).pipe(
      map(r => r as Reservation)
    );
  }

  /**
   * Path part for operation finishRequestAssignSpaceEmployee
   */
  static readonly FinishRequestAssignSpaceEmployeePath = '/spaces/request/finish_assign';

  /**
   * Finish assignment of free space to employees for next laboral date by admin.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `finishRequestAssignSpaceEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  finishRequestAssignSpaceEmployee$Response(params?: {

  }): Observable<void> {

    const req = new SwaggerSpacesDefinitionsRequestBuilder(RequestsService.FinishRequestAssignSpaceEmployeePath, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Finish assignment of free space to employees for next laboral date by admin.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `finishRequestAssignSpaceEmployee$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  finishRequestAssignSpaceEmployee(params?: {

  }): Observable<void> {

    return this.finishRequestAssignSpaceEmployee$Response(params).pipe(
      map(r => r as void)
    );
  }

}
