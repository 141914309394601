import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '@app/shell/header/services/header.service';
import { StorageService } from '@core/services/storage/storage.service';
import { Router } from '@angular/router';
import { AppUrls } from '@config/app-urls.config';
import { AuthService } from '@core/services/auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GenericModalComponent } from '@shared/modules/generic-modal/generic-modal.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  userCompleteName: any;
  private destroyed$: Subject<void> = new Subject<void>();

  constructor(public headerService: HeaderService,
              private storageService: StorageService,
              private router: Router,
              private authService: AuthService,
              private modalController: ModalController) {
  }

  ngOnInit(): void {
    // this.headerService.getLoggedUser();
    this.setLoggedUser();
  }

  async logoutConfirmation() {
    const modal = await this.modalController.create({
      component: GenericModalComponent,
      cssClass: 'confirmation-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        firstTitle: 'MODAL.LOGOUT_CONFIRMATION',
        text: 'MODAL.LOGOUT_CONFIRMATION_TEXT',
        firstButton: { text: 'BUTTON.YES', action: 'logout' },
        secondButton: { text: 'BUTTON.NO' }
      }
    });

    await modal.present();

    modal.onWillDismiss().then((modalData) => {
      if (modalData.data.dismissed && modalData.data.info.actionButton === 'logout') {
        this.logout();
      }
    });
  }

  async logout(): Promise<void> {
    await this.storageService.remove('loggedUser');
    await this.authService.oAuthLogout();
    this.headerService.isHeaderVisible = false;
    await this.router.navigate([AppUrls.AppLogin], { replaceUrl: true });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setLoggedUser(): void {
    this.headerService.getInfoHeaderObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res.headerDesktopUserName) {
          this.userCompleteName = res.headerDesktopUserName;
        }
      });
  }
}
