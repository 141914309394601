import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Empty component for demo purposes. It shows how to properly handle subscriptions so as to
 * guarantee there are no performance issues.
 * For more extended example, please refer to demo module
 */
@Component({
  selector: 'esda-empty-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit, OnDestroy {
  // The 'destroy$' subject is used to guarantee that no subscription remains so as to avoid memory leaks
  // It should be used with 'takeUntil' operator when subscribing to observables
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    // This way we make sure the 'destroy$' subject emits on component's lifecycle end,
    // and that we are no longer subscribed to it
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
